module.exports = {
    url: '/passport/:type',
    views: {
        main: {
            controller: function ($scope, $interval, $rootScope, $state, expandCards,
                                  $window, PatientAlerts, expandedEntities, FormStore) {
                'ngInject';

                $scope.loaded = false;

                const supportedPassports = {
                    full: { title: 'Full Passport', form: 'full_passport'},
                    emergency: { title: 'Emergency Passport', form: 'emergency_passport'}};

                let selectedPassport = supportedPassports[$state.params.type];
                if (selectedPassport){

                    $scope.dailyAssistanceLabel = selectedPassport.title;
                    $window.document.title = $scope.dailyAssistanceLabel;


                    $scope.$on('IdleTimeout', function () {
                        $rootScope.logout();
                    });
                    $scope.formState = {};
                    $scope.patient = $scope.$parent.patient;

                    $scope.sortedRepForms = [];
                    FormStore.loadPatientForms().then(function (Form) {
                        Form.getForm({
                            patientId: $scope.patient.id,
                            formName: selectedPassport.form
                        }, function(resp){

                            $scope.passportForm = resp;
                            $scope.passportForm.state = {};

                            if ($scope.patient.alerts && $scope.patient.alerts.assessment &&
                                $scope.patient.alerts.assessment[selectedPassport.form]) {
                                PatientAlerts.setRepFormState($scope.patient.alerts.assessment[selectedPassport.form],
                                    $scope.passportForm.state);
                            }

                            $scope.loaded = true;
                            expandCards[$scope.passportForm.name] = true;

                        });
                    });

                    expandedEntities.static = {};
                    expandedEntities.repeating = {};
                } else {
                    $scope.unknownPassport = true;
                }



            },
            template: `
      <div class="assessment" ng-if="loaded">
        <repeating-form
           form='passportForm'
           patient='patient'>
        </repeating-form>
      </div>
      <div class="layout-fill" ng-if="unknownPassport">
        <center><h3>Unknown Passport Type</h3></center>
       </div>
      `
        },
        title: {
            controller: function ($scope) {
                'ngInject';

                $scope.dailyAssistanceLabel = 'Passport Setup';
            },
            template: `
      {{ dailyAssistanceLabel }}
      `
        }
    }
};
