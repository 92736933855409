/**
 * @name history
 * @ngdoc module
 * @description
 * Graph making directive
 */

angular.module('history', [])

    .directive('historyTime', function () {
        return {
            restrict: 'E',
            scope: {
                time: '=',
                formVals: '=',
                formName: '=',
                formType: '=',
                onlyIcon: '='
            },
            controller: function ($scope, signOffInfo, $state, $rootScope, Utils,
                                  PatientStore, PastPatientObject, PatientDecorator, $mdDialog) {

                $scope.trainee = $rootScope.trainee;

                if ($scope.time.veryFirstRecord) {
                    let diffFromNow = (moment().diff(moment($scope.time.timestamp), 'minutes'));
                    if (diffFromNow <= 30) {
                        $scope.time.editable = true;
                    } else {
                        $scope.time.notEditable = true;
                    }
                }

                if ($scope.time.originalTimestamp) {
                    $scope.time.amended = true;
                    $scope.time.formattedTimestamp = Utils.formatBatchTime($scope.time.originalTimestamp);
                }

                $scope.readmitDialog = function (ev) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        template: `
        <md-dialog>
          <md-dialog-content>
            <div class="ts-dialog">
              <div layout="row" class='section'>
                <div flex>
                  This entry is from previous admission.
                </div>
              </div>
            </div>
          </md-dialog-content>
        </md-dialog>
        `,
                        controller: function DialogController($scope, $mdDialog) {
                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }
                    });
                };

                $scope.amendedTimestampDialog = function (ev) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        locals: {time: $scope.time},
                        template: `
          <md-dialog>
          <md-dialog-content>
          <div class='ts-dialog'>
            <div class='row section'>
              <div class='small-11 columns heading'>
                Timestamp Adjusted
              </div>
              <div class='small-1 columns no-horizontal-padding'>
                <i class='fas fa-times close-dialog' ng-click='closeDialog()'></i>
              </div>
            </div>
            <div class='row section'>
              <div class='small-12 columns'>
                <p> The timestamp for this entry has been adjusted
                </p>
              </div>
            </div>
            <div class='row section'>
              <div class='small-12 columns jet-black'>
                Original Timestamp
              </div>
              <div class='small-12 columns'>
                <span ng-bind='time.formattedTimestamp.date'></span>
                <span ng-bind='time.formattedTimestamp.time'></span>
              </div>
            </div>
            <div class='row section'>
              <div class='small-12 columns jet-black'>
                Reason for adjustment
              </div>
              <div class='small-12 columns'>
                <span ng-bind='time.timestampAmendedReason'></span>
              </div>
            </div>
            <div class='row align-right'>
              <div class='small-12 columns'>
                <button class='tiny' ng-click='closeDialog()'>OK</button>
              </div>
            </div>
        </div>
        </md-dialog>
        </md-dialog-content>
        `,
                        controller: function DialogController($scope, $mdDialog, time) {
                            $scope.time = time;
                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }

                    });
                };

                $scope.editTimeDialog = function (ev) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        locals: {time: $scope.time},
                        template: `
          <md-dialog>
          <md-dialog-content>
          <div class='ts-dialog'>
            <div layout class='section'>
              <div style='padding-left: 0' flex='95' class='heading'>
                Adjust Timestamp
              </div>
              <div flex>
                <i class='fas fa-times close-dialog' ng-click='closeDialog()'></i>
              </div>
            </div>
            <div layout class='section'>
              <p> Adjust the slider to select the correct timestamp,
                  add your reason for adjustment and click OK.
              </p>
            </div>


            <div layout='column' style='font-size: 1.25em'>
              <div class='center'>
                {{ datetime.date || 'Date' }}
              </div>
              <div class='center'>
                {{ datetime.time || 'Time' }}
              </div>
            </div>

            <div ng-show='info' style='margin-top: 1em; color: #d40000;' ng-bind='info'></div>

            <div layout style='margin-bottom: 1em; margin-top: .5em;'>
              <md-slider class='no-horizontal-margin' ng-model="disabled" ng-disabled="true"
                         aria-label="Disabled">
              </md-slider>
              <md-slider aria-label='DateTime Selector'
                         class='no-left-margin'
                         ng-model="datetime.value"
                         min="{{ datetime.min }}"
                         ng-change='sliderChanged()'
                         max="{{ datetime.max }}">
              </md-slider>
            </div>

            <div layout='column' class='section'>
              <div>
                Reason for timestamp adjustment
              </div>
              <div>
              <textarea class='reason' ng-model='reason'>
              </textarea>
              </div>
            </div>

            <div ng-show='warning' layout class='section'>
              <div class='message-warning'>
                <i style='font-size:1.5em' class="fas fa-exclamation-triangle"></i>
                <span style='vertical-align: text-bottom' ng-bind='warning'></span>
              </div>
            </div>

            <div layout='row' layout-xs='column' layout-margin layout-fill layout-align='end'>
              <button style='margin-right: 0'
                      ng-click='submitNewTimestamp()'>OK</button>
              <button style='margin-right: 0'
                      class='cancel'
                      ng-click='closeDialog()'>Cancel</button>
            </div>

          </div>
          </md-dialog>
          </md-dialog-content>
        `,
                        controller: function DialogController($scope, $mdDialog, $rootScope,
                                                              RepeatingFormStore, $state, PreviousRecordInfo,
                                                              time, $timeout) {
                            $scope.time = time;
                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };

                            $scope.datetime = {max: 240, value: 240};

                            let lastTs = moment($scope.time.timestamp).diff(moment(PreviousRecordInfo.time), 'minutes');
                            $scope.datetime.min = Math.max(0, 240 - lastTs);

                            $timeout(function () {
                                document.querySelector('div.md-thumb-container').remove();
                                let enabledSliderValue = Math.min(Math.floor(lastTs * (100 / 240)), 100);
                                let disabledSlider = angular.element(document.querySelectorAll('md-slider')[0]);
                                let enabledSlider = angular.element(document.querySelectorAll('md-slider')[1]);
                                enabledSlider.css('min-width', enabledSliderValue + '%');
                                disabledSlider.css('min-width', (100 - enabledSliderValue) + '%');
                            });


                            $scope.sliderChanged = function () {
                                $scope.info = '';
                                let value = $scope.datetime.value;
                                $scope.datetime.ts = moment($scope.time.timestamp).add((value - $scope.datetime.max), 'minutes');
                                $scope.datetime.date = $scope.datetime.ts.format('DD/MM/YYYY');
                                $scope.datetime.time = $scope.datetime.ts.format('hh:mm A');

                                if (value === $scope.datetime.min) {
                                    if (value === 0) {
                                        $scope.info = 'Sorry, timestamp adjustments can only be set within four hours of the entry.';
                                    } else {
                                        $scope.info = 'Sorry, timestamp adjustments cannot be set before the previous entry.';
                                    }
                                }
                            };

                            $scope.sliderChanged();

                            $scope.reason = '';
                            $scope.warning = '';

                            $scope.submitNewTimestamp = function () {

                                if (!$scope.datetime.date) {
                                    $scope.warning = 'Please Enter Date/Time';
                                    return;
                                }

                                if (!$scope.reason) {
                                    $scope.warning = 'Please Enter reason';
                                    return;
                                }


                                let params = {
                                    id: $rootScope.patientId,
                                    repId: $scope.time.id
                                };


                                let data = {
                                    timestamp: $scope.datetime.ts.unix() * 1000,
                                    reason: $scope.reason
                                };

                                RepeatingFormStore.load().then(RepeatingData => {
                                    RepeatingData.updateTS(params, data, function () {
                                        $mdDialog.hide();
                                        $state.reload();
                                    }, err => {
                                    });
                                });
                            };
                        }
                    });
                };


                $scope.showUnavailableDialog = function (ev) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose: true,
                        template: `
          <md-dialog>
            <md-dialog-content>
              <div class='ts-dialog'>
                <div class='row section'>
                  <div class='small-11 columns heading'>
                    Timestamp Adjustment Unavailable
                  </div>
                  <div class='small-1 columns no-horizontal-padding'>
                    <i class='fas fa-times close-dialog' ng-click='closeDialog()'></i>
                  </div>
                </div>
                <div class='row section'>
                  <div class='small-12 columns'>
                    <p>Sorry, we cannot adjust the timestamp for<br>
                       this entry because more than 30 minutes <br>
                       have passed since it was submitted.
                    </p>
                  </div>
                </div>
                <div class='row align-right'>
                  <div class='small-12 columns'>
                    <button ng-click='closeDialog()'>OK</button>
                  </div>
                </div>
              </div>
            </md-dialog-content>
          </md-dialog>
         `,
                        controller: function DialogController($scope, $mdDialog) {
                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }
                    });
                };

                $scope.accessForm = function () {
                    if ($rootScope.trainee || !$rootScope.canSubmitForms || ($scope.time.approved)) {
                        return;
                    }
                    signOffInfo.formVals = $scope.formVals;
                    signOffInfo.submissionDateTime = Utils.formatBatchTime($scope.time.timestamp);
                    $rootScope.signoffLater.value = true;
                    signOffInfo.repId = $scope.time.id;
                    signOffInfo.form = $scope.formName;
                    signOffInfo.username = $scope.time.user;


                    PatientStore.load().then(Patient => {
                        Patient.history({id: $rootScope.patientId, timestamp: $scope.time.timestamp},
                            function (patient) {

                                PatientDecorator.addCardFunctions(patient);
                                PastPatientObject.value = patient.data;

                                if ($scope.formType == 'repeating') {
                                    signOffInfo.assessment = true;
                                    $state.go('patient.assessment', {card: $scope.formName});
                                } else {
                                    signOffInfo.careplan = true;
                                    $state.go('patient.careplans', {card: $scope.formName});
                                }
                            }, err => {
                            });
                    });
                };
            },
            template: `

      <div layout>
        <div flex='100' ng-if='!onlyIcon' ng-click='accessForm()'>
          <div ng-bind='time.day'></div>
          <div ng-class='["date", {"unapproved": !time.approved, "no-border": time.readmit}]'
               ng-bind='time.date'></div>
          <div class='time' ng-bind='time.time'></div>
          <div class='time' ng-bind='time.user'></div>
        </div>

        <div flex ng-if='!onlyIcon && time.showDivider' class='readmit-separator'>
          <i class="icon3-readmission-full readmission"></i>
          <i style='top: 6px' class="fas fa-chevron-down"></i>
          <svg>
              <line x1="0" y1="0" x2="0" y2="64" style="stroke:#ff7400; stroke-width:4" />
          </svg>
        </div>
    </div>

      <div layout='row' layout-align='center center'>
        <div flex='20' flex-xs='50' ng-if='!time.approved' ng-click='accessForm()'>
          <i class='icon2-unapproved trainee-light'></i>
        </div>

        <div flex='20' flex-xs='50' ng-if='time.editable && time.approved && !time.amended && !trainee && !time.readmit'
             ng-click='editTimeDialog($event)'>
          <i style='color:#45b1d3' class='fas fa-clock'></i>
        </div>

        <div flex='20' flex-xs='50' ng-if='time.notEditable && time.approved && !time.amended && !time.readmit'
             ng-click='showUnavailableDialog($event)'>
          <i style='color:#babdb6' class='far fa-clock'></i>
        </div>

        <div flex='20' flex-xs='50' ng-if='time.amended && time.approved'
             ng-click='amendedTimestampDialog($event)'>
          <i style='color:#45b1d3' class='far fa-clock'></i>
        </div>

        <div flex='20' flex-xs='50' ng-if='time.readmit' ng-click='readmitDialog()'>
          <i class='icon3-readmission-half'></i>
        </div>

      </div>

    `
        };
    })

    .directive('valueBox', function (Utils) {
        return {
            scope: {
                times: '=',
                values: '=',
                field: '=',
                toggleStatus: '='
            },
            controller: function ($scope) {
                $scope.finalValues = [];
                $scope.values.forEach(value => {
                    if (!value) {
                        value = {};
                    }
                    var currentVal = value[$scope.field.name];
                    if (currentVal) {
                        $scope.finalValues.push(value.meta[$scope.field.name].text);
                    } else {
                        $scope.finalValues.push('');
                    }
                });

                $scope.toggle = function () {
                    $scope.toggleStatus = false;
                };
            },
            template: `
      <div class='radio-button-box'>
        <div class='row no-horizontal-margin desc-bar'>
          <div class='small-2 columns'>Date</div>
          <div class='small-2 columns'>Time</div>
          <div class='small-7 columns'>Detail</div>
          <div ng-click='toggle()'
               class='small-1 columns text-center increased-size'>
            <i class='fas fa-angle-up'></i>
          </div>
        </div>
        <div class='details'>
          <div class='row no-horizontal-margin' ng-repeat='item in times'>
            <div class='small-2 columns'>
              <span ng-bind='item.date'></span>
              <span ng-bind='item.month'></span>
            </div>
            <div class='small-2 columns'>
              <span ng-bind='item.time'></span>
            </div>
            <div class='small-8 columns'>
              <span ng-bind='finalValues[$index]'></span>
            </div>
          </div>
        </div>
      </div>
    `
        };
    })

    .directive('graph', function () {
        return {
            scope: {
                data: '=',
                ydomain: '=',
                tickvalue: '=',
                toggleStatus: '=',
                xdomain: '=',
                xticks: '=',
                bp: '='
            },
            controller: function ($scope) {

                $scope.$watch('data', function () {
                    $scope.refresh();
                });

                $scope.refresh = function () {

                    if (!$scope.ydomain) {

                        var allYVals = $scope.data.map(val => {
                            return val.y;
                        });
                        var minYVal = Math.min.apply(Math, allYVals);
                        var maxYVal = Math.max.apply(Math, allYVals);

                        $scope.inferredYDomain = [Math.floor(minYVal - (minYVal / 10)),
                            Math.ceil(maxYVal + (maxYVal / 10))];
                    }

                    function bpFormat(data) {
                        var formattedData = [];
                        data.forEach(val => {
                            var line = [{x: val.x, y: val.y.sys}, {x: val.x, y: val.y.dia}];
                            formattedData.push({
                                values: line,
                                color: '#56a1b0',
                                key: line[0].x
                            });
                        });
                        return formattedData;
                    }

                    if ($scope.bp) {
                        $scope.formattedData = bpFormat($scope.data);
                    } else {
                        $scope.formattedData = [{values: $scope.data, color: '#56a1b0'}];
                    }

                    $scope.config = {
                        deepWatchData: false,
                        deepWatchDataDepth: 0
                    };

                    $scope.toggle = function () {
                        $scope.toggleStatus = false;
                    };

                    if ($scope.tickvalue && $scope.tickvalue.length > 1) {
                        $scope.height = $scope.tickvalue.length * 20;
                    } else {
                        $scope.height = 150;
                    }

                    $scope.options = {
                        chart: {
                            type: 'lineChart',
                            margin: {
                                top: 6,
                                right: 0,
                                bottom: 35,
                                left: 26
                            },
                            height: $scope.height,
                            tooltip: {
                                contentGenerator: function (d) {
                                    return d3.time.format('%H:%M,%d/%m')(new Date(d.value)) + ', ' + d.series[0].value;
                                }
                            },
                            showLegend: false,
                            xAxis: {
                                tickFormat: function (date) {
                                    return d3.time.format('%H:%M,%d/%m')(new Date(date));
                                },
                                tickValues: $scope.xticks,
                                showMaxMin: false,
                                rotateLabels: 15
                            },
                            xDomain: $scope.xdomain,
                            duration: 200,
                            yAxis: {
                                showMaxMin: true,
                                tickValues: $scope.tickvalue
                            },
                            zoom: {
                                enabled: true,
                                horizontalOff: false,
                                verticalOff: true,
                                scaleExtent: [1, 1]
                            },
                            yDomain: $scope.ydomain || $scope.inferredYDomain
                        }
                    };

                };

                $scope.refresh();

            },
            template: `
    <div class='row no-horizontal-margin'>
      <div class='small-offset-10 small-2 columns text-center orange'
           ng-click='toggle()'>
        <i class='fas fa-minus'></i>
      </div>
    </div>
    <div class='row no-horizontal-margin'>
      <div class='small-12 columns'>
        <nvd3 data='formattedData'
          options='options'
          config='config'></nvd3>
      </div>
    </div>
    `
        };
    })

    .directive('unapprovedBar', function () {
        return {
            template: `
    <div class='row'>
      <div class='small-2 columns'>
      </div>

      <div class="small-2 columns text-center"
           ng-repeat='time in hfc.currentTimes'>
        <history-time form-vals='hfc.currentValues[$index]'
                      time='time'
                      form-type='hfc.formType'
                      only-icon='true'
                      form-name='hfc.formName'>
        </history-time>
      </div>
      <div class='small-2 columns'>
      </div>
    </div>
    `
        };
    })

    .directive('timeBar', function () {
        return {
            template: `
    <div>
        <div class='row'>

        <div class='small-2 columns'>
        </div>

          <div class='small-2 columns'
               ng-bind='month'
               ng-repeat='month in hfc.months track by $index'>
          </div>
          <div class='small-2 columns'>
          </div>
        </div>

        <div class='row'>

          <div class='small-2 columns'>
          </div>

          <div class="small-2 columns text-center"
               ng-repeat='time in hfc.currentTimes'>
            <history-time form-vals='hfc.currentValues[$index]'
                          time='time'
                          form-type='hfc.formType'
                          form-name='hfc.formName'>
            </history-time>
          </div>
          <div class='small-2 columns'>
          </div>
        </div>
      </div>
    `
        };
    })
    .controller('HistoryFormController', HistoryFormController)

    .directive('historyForm', function () {
        return {
            restrict: 'E',
            scope: {},
            bindToController: {
                formName: '=',
                patientId: '=',
                patient: '='
            },
            controllerAs: 'hfc',
            controller: 'HistoryFormController',
            template: ` <div class='value-toggle' ng-click='hfc.toggleEverything()'>
      <button class='small' ng-if='hfc.allClosed'>
        <i class='fas fa-chart-line fa-2x'></i>&nbsp;&nbsp;
        <span ng-bind='"DISPLAY GRAPHS"'></span>
      </button>

      <button class='small' ng-if='!hfc.allClosed'>
        <i class='fas fa-list fa-2x'></i>&nbsp;&nbsp;
        <span ng-bind='"CLOSE GRAPHS"'></span>
      </button>

    </div>
    <div class='history-container'>
      <div class='row'>
        <div class='small-8 columns'>
          <i class='fas fa-hourglass-end teal-velvet'></i>
          <label chai-markdown='"History: " + hfc.form.label'></label>

        </div>
        <div class='small-4 columns text-right'>
        <a ng-if='hfc.formType == "repeating"' ui-sref='patient.assessment({card: hfc.formName})'>
          <i class='fas fa-chevron-circle-left'></i>
          <span> Back to form </span>
        </a>
        <a ng-if='(hfc.formType == "care-plan" && !hfc.inactiveCareplan)'
           ui-sref='patient.careplans({card: hfc.formName})'>
          <i class='fas fa-chevron-circle-left'></i>
          <span> Back to form </span>
        </a>
        <a ng-if='(hfc.formType == "care-plan" && hfc.inactiveCareplan)'
           ui-sref='patient.careplans({card: ""})'>
          <i class='fas fa-chevron-circle-left'></i>
          <span> Back to Careplans</span>
        </a>

        </div>
      </div>

    <div class='repeating-history'>


      <div class='row'>
        <div class='small-2 columns brilliant-sky'>
          <i ng-click='hfc.changePage(1)'
               ng-if='hfc.currentPage < hfc.maxPages'
               class='fas fa-angle-left fa-2x'>
            </i>
        </div>
        <div class='small-2 columns brilliant-sky text-right'>
          <i ng-click='hfc.changePage(-1)'
               ng-if='hfc.currentPage !== 0'
               class='fas fa-angle-right fa-2x'>
            </i>
        </div>
      </div>

    <time-bar></time-bar>

    <div ng-repeat='card in hfc.form.cards'>

      <div ng-if='card.repeatable'>
        <div ng-repeat='i in hfc.repeatCounts[card.name] track by $index' ng-if='!hfc.toNotShow[card.name].cards[$index].overall'>
          <history-card 
                        card='card'
                        current-values='hfc.currentValues'
                        current-times='hfc.currentTimes'
                        card-graph-vals='hfc.graphVals[card.name].cards[$index]'
                        xdomain='hfc.xDomain' xticks='hfc.xTicks'
                        index='$index'
                        to-not-show='hfc.toNotShow[card.name].cards[$index]'
                        not-show-sections='hfc.notShowSections[card.name].cards[$index]'
                        next-view='hfc.nextView[card.name].cards[$index]'>
          </history-card>
        </div>
      </div>

      <div ng-if='!card.repeatable'>
        <history-card card='card'
                      current-values='hfc.currentValues'
                      current-times='hfc.currentTimes'
                      card-graph-vals='hfc.graphVals[card.name]'
                      xdomain='hfc.xDomain' xticks='hfc.xTicks'
                      to-not-show='hfc.toNotShow[card.name]'
                      not-show-sections='hfc.notShowSections[card.name]'
                      next-view='hfc.nextView[card.name]'>
        </history-card>
      </div>

      </div>


    <unapproved-bar></unapproved-bar>


    </div>
    </div>`
        };

    })

    .directive('historyCard', function ($rootScope, $parse, Utils, $mdDialog) {
        return {
            scope: {
                card: '=',
                nextView: '=',
                currentValues: '=',
                currentTimes: '=',
                cardGraphVals: '=',
                xdomain: '=',
                xticks: '=',
                toNotShow: '=',
                notShowSections: '=',
                index: '='
            },
            controller: function ($scope) {

                $scope.serverUrl = $rootScope.serverUrl;

                $scope.$watch('currentValues', function () {
                    $scope.cardValues = $scope.currentValues.map(value => {
                        if (!$scope.card.repeatable) {
                            return value[$scope.card.name];
                        } else {
                            return value[$scope.card.name].cards[$scope.index] || {};
                        }
                    });
                });

                $scope.formatValue = function (cardData, field) {
                    if (cardData === undefined || cardData[field.name] === undefined || cardData.archive) {
                        return;
                    }

                    if (cardData.meta[field.name].text === undefined) {
                        return;
                    }

                    var value = cardData.meta[field.name].text;
                    return value.slice(0, 7);


                };

                $scope.getPreview = function () {
                    var previewFunc = $parse($scope.card.preview);
                    return previewFunc($scope.cardValues[$scope.cardValues.length - 1]);

                };

                $scope.toggleNextView = function (card_name, field_name) {
                    $scope.nextView[field_name] = !$scope.nextView[field_name];
                };

                $scope.showWarning = function (message) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        locals: {message: message},
                        clickOutsideToClose: true,
                        template: `
            <md-dialog aria-label='Warning Details'>
              <div layout-align="center center" layout-margin layout-padding>
                <span ng-bind='message'></span>
              </div>
            </md-dialog>
          `,
                        controller: function DialogController($scope, message) {
                            $scope.message = message;
                        }
                    });
                };


                $scope.showImage = function (image) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        locals: {image: image},
                        clickOutsideToClose: true,
                        template: `
            <md-dialog aria-label='Warning Details'>
              <div layout-align="center center" layout-margin layout-padding>
                <img http-src="{{image}}" />
              </div>
            </md-dialog>
          `,
                        controller: function DialogController($scope, image) {
                            $scope.image = image;
                        }
                    });
                };

            },
            template: `
      <div style='card-divider'></div>

      <div class='row' ng-if='card.hasOwnProperty("preview")'>
       <div class='small-12 columns left-padding'>
          <label ng-bind='getPreview()' class='history-card-preview'></label>
        </div>
      </div>

      <div class='row'
           ng-if='!field.hide_history &&
                  (!toNotShow[field.name] || (field.type == "section" && !notShowSections[field.name]))'
           ng-repeat='field in card.fields'>

         <hr ng-if='field.type == "section"' class='section-hr'>

        <div ng-click='toggleNextView(card.name, field.name)'
             ng-if='field.label'
             class='small-12 columns left-padding'>
          <label chai-markdown='field.label'
                 ng-class='{"card-section": field.type == "section"}'></label>
        </div>

        <div ng-if='!nextView[field.name] ||
                    field.type == "check_box" ||
                    field.type == "time_field"'
             class='small-2 columns'>
        </div>

        <div ng-if='!nextView[field.name] ||
                    field.type == "check_box" ||
                    field.type == "time_field"'
             class="small-2 columns text-center"
             ng-repeat='value in cardValues'>
          <span ng-if='field.type !== "image_input"'
                ng-bind='formatValue(value, field)'>
          </span>
          <span ng-click='showImage(serverUrl + value[field.name])'
                ng-if='field.type == "image_input" && value[field.name]'>
            <img style='max-height: 4em;'
                 http-src="{{serverUrl + value[field.name]}}" />
            <i class='fas fa-search-plus fa-stack-2x'></i>
          </span>
          <i ng-if='value.meta[field.name].warning.color'
             ng-style='{"color": value.meta[field.name].warning.color}'
             ng-click='showWarning(value.meta[field.name].warning.message)'
             class='fa fa-exclamation-triangle'></i>
        </div>

        <div ng-if='!nextView[field.name]'
             class='small-2 columns text-center right'
             ng-click='toggleNextView(card.name, field.name)'>
          <i class='fas fa-chevron-down'
                ng-if='field.type == "radio_buttons" ||
                       field.type == "multiple_checkboxes" ||
                       field.type == "text_area" ||
                       field.type == "text_input" ||
                       field.type == "repeat_period" ||
                       field.type == "date_field"'></i>

            <i class='fas fa-chart-line'
               ng-if='field.type == "number_field" ||
                      field.type == "blood_pressure" ||
                      field.type == "slider" ||
                      field.type == "aggregate"'></i>
        </div>

        <div class='small-12 columns white' ng-if='nextView[field.name]'>

          <div ng-if='field.type == "number_field" || field.type == "slider" ||
                      field.type == "aggregate"'>
           <graph ydomain='field.ydomain' xdomain='xdomain' xticks='xticks'
                  tickvalue='field.tickvalue'
                  data='cardGraphVals[field.name]'
                  toggle-status='nextView[field.name]'></graph>
          </div>

          <div ng-if='field.type == "radio_buttons" ||
                      field.type == "multiple_checkboxes" ||
                      field.type == "text_area" ||
                      field.type == "text_input" ||
                      field.type == "repeat_period" ||
                      field.type == "date_field"'>
            <value-box times='currentTimes'
                              values='cardValues'
                              field='field'
                              toggle-status='nextView[field.name]'>
            </value-box>
          </div>

          <div ng-if='field.type == "blood_pressure"'>
           <graph ydomain='field.ydomain' xdomain='xdomain' xticks='xticks'         
                    tickvalue='field.tickvalue'
                    data='cardGraphVals[field.name]'
                    toggle-status='nextView[field.name]'
                    bp=1>
           </graph>
          </div>

        </div>

        <hr ng-if='field.type !== "section"'>


      </div>
      <hr ng-if='card.hasOwnProperty("preview")' class='preview-hr'>

    `
        };
    });

/**
 * Controller for Form History
 * @param $scope
 * @param RepeatingFormStore
 * @param Utils
 * @param Cards
 * @constructor
 */

/*jshint latedef:false*/
function HistoryFormController(RepeatingFormStore, Utils, Cards, cleverCards, $rootScope,
                               PreviousRecordInfo) {
    var hfc = this;

    var advanceFetch = 2;
    var sections = {};

    hfc.currentPage = 0;
    hfc.maxPages = -1;
    hfc.portraitColumns = 4;
    hfc.times = [];
    hfc.values = [];
    hfc.repeatCounts = {};
    hfc.nextView = {};
    hfc.graphVals = {};
    hfc.toNotShow = {};
    hfc.notShowSections = {};
    hfc.currentTimes = [];
    hfc.currentValues = [];
    hfc.months = [];
    hfc.allClosed = true;
    hfc.inactiveCareplan = $rootScope.inactiveCareplan;
    hfc.xDomain = [new Date('October 09, 2018 00:00:00').getTime(), new Date('October 10, 2018 00:00:00').getTime()];

    function arrangeMonths(times) {
        hfc.months = [];
        var currentMonth;
        for (var i = 0; i < times.length; i++) {
            if (i === 0) {
                hfc.months.push(times[i].month);
                currentMonth = times[i].month;
            } else {
                if (times[i].month == currentMonth) {
                    hfc.months.push('');
                } else {
                    hfc.months.push(times[i].month);
                    currentMonth = times[i].month;
                }
            }
        }
    }

    function arrangeSections() {
        hfc.form.cards.forEach(card => {
            sections[card.name] = {};
            var currentSection;
            card.fields.forEach(field => {
                if (field.type == 'section') {
                    currentSection = field.name;
                    sections[card.name][currentSection] = [];
                } else {
                    if (currentSection) {
                        sections[card.name][currentSection].push(field.name);
                    }
                }
            });
        });
    }

    function setNotShowSections() {
        /*jshint loopfunc:true */
        for (var i in hfc.notShowSections) {
            if (hfc.notShowSections[i].hasOwnProperty('cards')) {
                for (var ix = 0; ix < hfc.notShowSections[i].cards.length; ix++) {

                    for (var j in sections[i]) {
                        var toNotShowSection = true;
                        for (var k in sections[i][j]) {
                            var toNotShowField = hfc.toNotShow[i].cards[ix][sections[i][j][k]];
                            if (!toNotShowField) {
                                toNotShowSection = false;
                                break;
                            }
                        }
                        hfc.notShowSections[i].cards[ix][j] = toNotShowSection;
                    }

                }
            } else {
                for (var jx in sections[i]) {
                    var _toNotShowSection = true;
                    for (var kx in sections[i][jx]) {
                        var _toNotShowField = hfc.toNotShow[i][sections[i][jx][kx]];
                        if (!_toNotShowField) {
                            _toNotShowSection = false;
                            break;
                        }
                    }
                    hfc.notShowSections[i][jx] = _toNotShowSection;
                }
            }
        }
    }

    function setNotShowFields(values) {
        /*jshint loopfunc:true */
        hfc.form.cards.forEach(card => {
            if (card.repeatable) {
                //if everything is hidden in every visible instance of the card, hide the whole card


                for (var ix = 0; ix < hfc.repeatCounts[card.name].length; ix++) {
                    //inititally assume nothing visible for the card, so don't show it
                    hfc.toNotShow[card.name].cards[ix].overall = true;

                    card.fields.forEach(field => {
                        var hideField = true;
                        for (var i = 0; i < values.length; i++) {
                            //
                            if (ix < values[i][card.name].cards.length && values[i][card.name].cards[ix].hasOwnProperty(field.name)) {

                                //now check it's not hidden
                                if (!values[i][card.name].cards[ix].archive && (!field.hasOwnProperty('disable') ||
                                    cleverCards.evalDisable(field.disable, hfc.patient.data, values[i][card.name].cards[ix]) !== true)) {
                                    hideField = false;
                                    break;
                                }
                            }
                        }

                        //if something is visible for the card, we show it
                        if (!hideField) {hfc.toNotShow[card.name].cards[ix].overall = false;}
                        hfc.toNotShow[card.name].cards[ix][field.name] = hideField;
                    });


                }


            } else {
                card.fields.forEach(field => {
                    var toNotShow = true;
                    for (var i = 0; i < values.length; i++) {
                        if (values[i][card.name])
                            {if (values[i][card.name].hasOwnProperty(field.name)) {
                                if (!field.hasOwnProperty('disable') ||
                                    cleverCards.evalDisable(field.disable, hfc.patient.data, values[i][card.name]) !== true) {
                                    toNotShow = false;
                                    break;
                                }

                            }}
                    }
                    hfc.toNotShow[card.name][field.name] = toNotShow;
                });
            }
        });
    }

    function graphFormat(values, card, field) {

        if (hfc.graphVals[card.name] == undefined) {
            if (!card.repeatable) {
                hfc.graphVals[card.name] = {};
            } else {
                hfc.graphVals[card.name] = {cards: []};
                for (var ix = 0; ix < hfc.repeatCounts[card.name].length; ix++) {
                    hfc.graphVals[card.name].cards.push({});
                }
            }
        }

        if (card.repeatable) {
            for (var i = 0; i < hfc.repeatCounts[card.name].length; i++) {
                if (!hfc.graphVals[card.name].cards[i][field]) {
                    hfc.graphVals[card.name].cards[i][field] = [];
                }


                /*jshint loopfunc:true */

                values.forEach(value => {
                    if (i < value.data[hfc.formName][card.name].cards.length) {
                        var yVal = value.data[hfc.formName][card.name].cards[i][field];
                        if (yVal !== undefined) {
                            var val = {
                                x: value.time,
                                y: yVal
                            };
                            hfc.graphVals[card.name].cards[i][field].push(val);
                        }
                    }
                });
            }
        } else {
            if (!hfc.graphVals[card.name][field]) {
                hfc.graphVals[card.name][field] = [];
            }
            values.forEach(value => {
                if (value.data[hfc.formName][card.name] !== undefined) {
                    var yVal = value.data[hfc.formName][card.name][field];
                    if (yVal !== undefined) {
                        var val = {
                            x: value.time,
                            y: yVal
                        };
                        hfc.graphVals[card.name][field].push(val);
                    }
                }
            });
        }

    }

    function getCurrent(data) {
        return data.slice(hfc.currentPage * hfc.portraitColumns,
            (hfc.currentPage + 1) * hfc.portraitColumns).reverse();
    }

    function fetchValues() {
        RepeatingFormStore.load().then(DataStore => {
            DataStore.valuesCount({
                id: hfc.patientId,
                card: hfc.formName,
                count: advanceFetch * hfc.portraitColumns,
                offset: (hfc.maxPages + 1) * hfc.portraitColumns
            }, function (values) {
                values.forEach((value, index) => {
                    let showDivider = false;
                    let veryFirstRecord = false;
                    if ((!PreviousRecordInfo.readmit) && (value.meta.previousAdmission === true)) {
                        showDivider = true;
                    }
                    PreviousRecordInfo.readmit = value.meta.previousAdmission;
                    if (hfc.currentPage === 0) {
                        if (index === 0) {
                            veryFirstRecord = true;
                        }
                        if (index === 1) {
                            PreviousRecordInfo.time = value.time;
                        }
                    }

                    value.data[hfc.formName] = angular.copy(value.data)
                    hfc.times.push(Utils.formatHistoryMeta(value.time,
                        value.id,
                        value.meta,
                        value.user,
                        veryFirstRecord,
                        showDivider));
                    hfc.values.push(value.data[hfc.formName]);
                });

                if (hfc.currentTimes.length === 0) {
                    hfc.currentTimes = getCurrent(hfc.times);
                    arrangeMonths(hfc.currentTimes);
                }

                if (hfc.currentValues.length === 0) {
                    hfc.currentValues = getCurrent(hfc.values);
                    if (values.length > 0) {
                        for (var repCard in hfc.repeatCounts) {
                            var maxLengths = values.map(value => {
                                return value.data[hfc.formName][repCard].cards.length
                            });
                            var maxLength = maxLengths.reduce(function (a, b) {
                                return Math.max(a, b);
                            })
                            hfc.repeatCounts[repCard] = Array(maxLength);
                            for (var k = 0; k < maxLength; k++) {
                                hfc.nextView[repCard].cards[k] = {};
                                hfc.toNotShow[repCard].cards[k] = {};
                                hfc.notShowSections[repCard].cards[k] = {};
                            }
                        }
                    }

                    setNotShowFields(hfc.currentValues);
                    setNotShowSections();
                }

                if (hfc.times.length > 0) {
                    var minX = hfc.times[hfc.times.length - 1].timestamp;
                    var maxX = hfc.times[0].timestamp;
                    //var graphStart = hfc.currentTimes[0].timestamp;
                    //var graphEnd = hfc.currentTimes[hfc.currentTimes.length-1].timestamp;

                    var xParams = Utils.getGraphXParameters(minX, minX, maxX, maxX);

                    hfc.xDomain = xParams.xDomain;
                    hfc.xTicks = xParams.xTicks;
                }

                hfc.form.cards.forEach(card => {
                    card.fields.forEach(field => {
                        if (field.type == 'number_field' ||
                            field.type == 'aggregate' || field.type == 'slider' ||
                            field.type == 'blood_pressure') {
                            graphFormat(values, card, field.name);
                        }
                    });
                });

                hfc.maxPages = Math.ceil(
                    hfc.values.length / hfc.portraitColumns) - 1;
            }, err => {
            });
        });
    }

    /**
     * Set up directive data.
     * Has to be in onInit to access the directive bindings.
     */
    this.$onInit = function () {

        Cards.get(hfc.formName, hfc.patientId).then(form => {
            hfc.form = form;
            hfc.formType = hfc.form.type;

            // prime the counts
            for (var cardIndex in form.cards) {
                if (form.cards[cardIndex].repeatable) {
                    hfc.repeatCounts[form.cards[cardIndex].name] = [];
                    hfc.nextView[form.cards[cardIndex].name] = {cards: []};
                    hfc.toNotShow[form.cards[cardIndex].name] = {cards: []};
                    hfc.notShowSections[form.cards[cardIndex].name] = {cards: []};
                } else {
                    hfc.nextView[form.cards[cardIndex].name] = {};
                    hfc.toNotShow[form.cards[cardIndex].name] = {};
                    hfc.notShowSections[form.cards[cardIndex].name] = {};
                }
            }

            arrangeSections();
            fetchValues();
        });
    };

    hfc.changePage = function (change) {
        hfc.currentPage += change;
        hfc.currentTimes = getCurrent(hfc.times);
        arrangeMonths(hfc.currentTimes);
        hfc.currentValues = getCurrent(hfc.values);
        setNotShowFields(hfc.currentValues);
        setNotShowSections();

        let values = hfc.currentValues;
        if (values.length > 0) {
            for (var repCard in hfc.repeatCounts) {
                var maxLengths = values.map(value => {
                    return value[repCard].cards.length
                });
                var maxLength = maxLengths.reduce(function (a, b) {
                    return Math.max(a, b);
                })
                let currentLength = hfc.repeatCounts[repCard].length;
                if (maxLength != currentLength) {
                    let diff = maxLength - currentLength
                    hfc.repeatCounts[repCard] = Array(maxLength);
                    for (var k = 0; k < maxLength; k++) {
                        hfc.nextView[repCard].cards[k] = {};
                        hfc.toNotShow[repCard].cards[k] = {};
                        hfc.notShowSections[repCard].cards[k] = {};
                    }
                }
            }
        }


        for (var i in hfc.nextView) {
            if (hfc.nextView[i].hasOwnProperty('cards')) {
                hfc.nextView[i].cards.forEach(card => {
                    for (var k in card) {
                        card[k] = false;
                    }
                });
            } else {
                for (var j in hfc.nextView[i]) {
                    hfc.nextView[i][j] = false;
                }
            }
        }

        if (hfc.currentPage === hfc.maxPages) {
            fetchValues();
        }

    };

    /*jshint loopfunc:true */
    hfc.toggleEverything = function () {
        hfc.allClosed = !hfc.allClosed;
        hfc.form.cards.forEach(card => {
            if (card.repeatable) {
                for (var i = 0; i < hfc.repeatCounts[card.name].length; i++) {
                    card.fields.forEach(field => {
                        hfc.nextView[card.name].cards[i][field.name] = !hfc.allClosed;
                    });
                }
            } else {
                card.fields.forEach(field => {
                    hfc.nextView[card.name][field.name] = !hfc.allClosed;
                });
            }
        });
    };

}
