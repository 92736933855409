/**
 * @ngdoc module
 * @name input
 * @description
 * Custom input components.
 */
angular.module('input', [])

    .directive('checkbox', function (Reject) {
        return {
            scope: {
                ngModel: '=',
                formName: '=',
                focus: '=',
                blur: '='
            },
            controller($scope) {
                $scope.next = function (state) {
                    if ($scope.formName) {
                        Reject.remove($scope.formName, 'self', 'self');
                    }
                    if (typeof $scope.ngModel === 'undefined') {
                        $scope.ngModel = state;
                    } else if ($scope.ngModel === state) {
                        $scope.ngModel = undefined;
                    } else {
                        $scope.ngModel = state;
                    }
                };

                $scope.inFocus = function () {
                    $scope.focus = true;
                    $scope.blur = false;
                };

                $scope.inBlur = function () {
                    $scope.focus = false;
                    $scope.blur = true;
                };

            },
            template: `
     <span tabindex="-1"
           ng-focus='inFocus()'
           ng-blur='inBlur()'
           class='checkbox-container'>
        <span ng-click='next(true)'
          class='checkbox'
          ng-class='{ "selected-true": ngModel === true,
                       "silver-grey" : ngModel === false }'>
          <i class='fas fa-check fa-2x'></i>
        </span>
        <span ng-click='next(false)'
          class='checkbox'
          ng-class='{ "selected-false": ngModel === false,
                       "silver-grey": ngModel === true }'>
          <i class='fas fa-times fa-2x'></i>
        </span>
      </span>
    `
        };
    })

    .directive('radioButton', function (Reject) {
        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                metaModel: '=',
                field: '=',
                formName: '=',
                focus: '=',
                blur: '='
            },
            controller: function ($scope) {

                function setMeta() {

                    if ($scope.ngModel[$scope.field.name].value == 'other') {
                        $scope.metaModel[$scope.field.name].text = $scope.ngModel[$scope.field.name].details;
                    } else {
                        $scope.metaModel[$scope.field.name].text = $scope.field.options[$scope.ngModel[$scope.field.name].value];
                    }

                    if ($scope.metaModel[$scope.field.name].text === undefined) {
                        $scope.metaModel[$scope.field.name].text = '';
                    }
                }

                $scope.change = function (value) {

                    if (!$scope.metaModel[$scope.field.name]) {
                        $scope.metaModel[$scope.field.name] = {};
                    }

                    if ($scope.formName) {
                        Reject.remove($scope.formName, 'self', 'self');
                    }
                    if (!$scope.ngModel[$scope.field.name]) {
                        $scope.ngModel[$scope.field.name] = {};
                    }
                    let currentValue = $scope.ngModel[$scope.field.name].value;
                    let latestValue = (currentValue === value) ?
                        undefined :
                        value;
                    $scope.ngModel[$scope.field.name].value = latestValue;

                    setMeta();

                };

                $scope.inFocus = function () {
                    $scope.focus = true;
                    $scope.blur = false;
                };

                $scope.inBlur = function () {
                    $scope.focus = false;
                    $scope.blur = true;
                };


                this.$onInit = function () {
                    // do this onload because when countersigning we may not change the field, in which
                    // case meta would never get set.
                    if ($scope.ngModel[$scope.field.name] !== undefined) {
                        setMeta();
                    }
                };


            },
            template: `
    <div tabindex="-1" ng-focus='inFocus()' ng-blur='inBlur()'>
    <div layout="row"
         ng-hide="field.collapsible && ngModel[field.name].value && ngModel[field.name].value !== value"
         ng-click='change(value)'
         ng-repeat='(value, name) in field.options'>
        <div class='right-padding'>
            <i ng-class='[
                   {"icon3-radio-checked": ngModel[field.name].value === value},
                   {"icon3-radio-blank": ngModel[field.name].value !== value}]'>
            </i>
        </div>
        <div flex class=''>
            <label chai-markdown='name'></label>
        </div>
    </div>

    <div class='row' ng-if='field.other'
         ng-hide="field.collapsible && ngModel[field.name].value && ngModel[field.name].value !== 'other'">
        <div class='small-2 columns right-margin'
             ng-click='change("other")'>
            <i ng-class='[
               {"icon3-radio-checked": ngModel[field.name].value === "other"},
               {"icon3-radio-blank": ngModel[field.name].value !== "other"}]'>
            </i>
        </div>
        <div class='small-10 columns move-left'
             ng-click='change("other")'>
            <label>Other</label>
        </div>
        <div class='small-12 columns'
             ng-if='ngModel[field.name].value === "other"'>
              <textarea ng-model='ngModel[field.name].details'
                        placeholder='If other, provide details'>
               </textarea>
        </div>
    </div>
</div>
    `
        };
    })

    .directive('doubleScrollTime', () => {
        return {
            restrict: 'E',
            scope: {
                ngModel: '='
            },
            controller: function ($scope, $mdDialog) {

                if ($scope.ngModel === undefined) {
                    $scope.ngModel = {};
                }

                $scope.setCurrentTime = function () {
                    var time = moment(Date.now());
                    var hour = time.hour().toString();
                    var minute = time.minute().toString();
                    $scope.ngModel.hour = hour.length == 1 ?
                        '0' + hour :
                        hour;
                    $scope.ngModel.minute = minute.length == 1 ?
                        '0' + minute :
                        minute;
                };

                $scope.showTimeModal = function () {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        locals: {ngModel: $scope.ngModel},
                        clickOutsideToClose: false,
                        template: `
            <md-dialog aria-label='Input Time'
                       md-colors="{color: 'primary-1200'}"
                       style='max-width: 12em'>
              <div layout-align="center center" layout-margin layout-padding>

                <div layout='row'>
                  <div flex='50' layout='column' layout-align='space-between center'>
                    <div>HH</div>

                    <div>
                      <i ng-click='changeHour(1)' class=' fas fa-chevron-up fa-2x'> </i>
                    </div>

                    <div>
                      <input class='noboxshadow time-input' type='text'
                             ng-pattern='/^[0-9]{0,2}$/'
                             ng-model='ngModel.hour' />
                    </div>

                    <div>
                      <i ng-click='changeHour(-1)' class=' fas fa-chevron-down fa-2x'></i>
                    </div>
                  </div>

                  <div flex='50' layout='column' layout-align='space-between center'>
                    <div>MM</div>

                    <div>
                      <i ng-click='changeMinute(1)' class=' fas fa-chevron-up fa-2x'></i>
                    </div>

                  <div>
                    <input class='noboxshadow time-input' type='text'
                           ng-pattern='/^[0-9]{0,2}$/'
                           ng-model='ngModel.minute' />
                  </div>

                  <div>
                    <i ng-click='changeMinute(-1)' class=' fas fa-chevron-down fa-2x'></i>
                  </div>

                  </div>

                </div>

            <div layout='row' ng-if='error' ng-bind='error'></div>
            <div layout='row' layout-align='center'>
              <md-button ng-disabled='!(ngModel.hour && ngModel.minute)'
                         ng-click='closeDialog()'
                         md-colors="{background: 'primary-600', color: 'primary-50'}">
                Ok
              </md-button>
            </div>


              </div>
            </md-dialog>
          `,
                        controller: function DialogController($scope, $mdDialog, ngModel) {
                            $scope.ngModel = ngModel;
                            $scope.error = '';

                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };

                            $scope.changeHour = function (change) {
                                if (!$scope.ngModel.hour) {
                                    $scope.ngModel.hour = '00';
                                    return;
                                }

                                var hour = parseInt($scope.ngModel.hour);
                                hour += change;
                                if (hour > 23) {
                                    $scope.ngModel.hour = '00';
                                    return;
                                }
                                if (hour < 0) {
                                    $scope.ngModel.hour = '23';
                                    return;
                                }
                                hour = hour.toString();
                                $scope.ngModel.hour = hour.length == 1 ?
                                    '0' + hour :
                                    hour.toString();
                            };

                            $scope.changeMinute = function (change) {
                                if (!$scope.ngModel.minute) {
                                    $scope.ngModel.minute = '00';
                                    return;
                                }

                                var minute = parseInt($scope.ngModel.minute);
                                minute += change;
                                if (minute > 59) {
                                    $scope.ngModel.minute = '00';
                                    return;
                                }
                                if (minute < 0) {
                                    $scope.ngModel.minute = '59';
                                    return;
                                }
                                minute = minute.toString();
                                $scope.ngModel.minute = minute.length == 1 ?
                                    '0' + minute :
                                    minute.toString();
                            };
                        }
                    });
                };

            },
            template: `
    <div>
    <a ng-click='showTimeModal()'>
    {{ngModel.hour || 'HH'}}
    </a> :
    <a ng-click='showTimeModal()'>
    {{ngModel.minute || 'MM'}}
    </a>
    <a ng-click='setCurrentTime()'> Set Current Time </a>

    `
        };
    })

    .directive('timePicker', function (Reject) {
        return {
            scope: {
                ngModel: '=',
                formName: '=',
                focus: '=',
                blur: '='
            },
            controller: function ($scope) {

                $scope.inFocus = function () {
                    $scope.focus = true;
                    $scope.blur = false;
                    if ($scope.formName) {
                        Reject.remove($scope.formName, 'self', 'self');
                    }
                };

                $scope.inBlur = function () {
                    $scope.focus = false;
                    $scope.blur = true;
                };
            },
            template: `
      <div tabindex='-1' ng-focus='inFocus()' ng-blur='inBlur()'>
      <double-scroll-time ng-model='ngModel'></double-scroll-time>
      </div>
    `
        };
    })

    .directive('datePicker', function (Reject) {
        return {
            scope: {
                ngModel: '=',
                formName: '=',
                focus: '=',
                blur: '=',
                disable: '='
            },
            require: 'ngModel',
            controller: function ($scope, $mdMedia) {

                $scope.$watch(function () {
                    return $mdMedia('gt-sm');
                }, function (big) {
                    if (big) {
                        $scope.todayStyle = {
                            'padding-left': '.5em',
                            'vertical-align': 'sub'
                        };
                    } else {
                        $scope.todayStyle = {'vertical-align': 'sub'};
                    }
                    $scope.bigScreen = big;
                });

                $scope.maxDate = new Date();

                if ($scope.ngModel) {
                    $scope.frontEndModel = moment($scope.ngModel, 'DD/MM/YYYY')._d;
                } else {
                    $scope.frontEndModel = undefined;
                }

                $scope.convertModel = function () {
                    $scope.ngModel = moment($scope.frontEndModel).format('DD/MM/YYYY');
                };

                $scope.inFocus = function () {
                    $scope.focus = true;
                    $scope.blur = false;
                    if ($scope.formName) {
                        Reject.remove($scope.formName, 'self', 'self');
                    }
                };

                $scope.inBlur = function () {
                    $scope.focus = false;
                    $scope.blur = true;
                };

                $scope.setToday = function () {
                    if ($scope.disable === false) {
                        return;
                    }
                    $scope.frontEndModel = moment()._d;
                    $scope.convertModel();
                };

                $scope.clear = function () {
                    if ($scope.disable === false) {
                        return;
                    }
                    $scope.frontEndModel = undefined;
                    $scope.ngModel = undefined;
                };

                $scope.$watch('isOpen', function (newValue, oldValue) {
                    if (!newValue && oldValue) {
                        document.querySelector('.md-datepicker-input').blur();
                    }
                });

            },
            template: `
      <div style='padding-bottom: .4em'>
        <md-datepicker ng-model="frontEndModel"
                       ng-focus='inFocus()'
                       md-open-on-focus 
                       ng-blur='inBlur()'
                       md-is-open='isOpen'
                       md-placeholder='Enter Date'
                       ng-disabled='disable === false'
                       md-current-view="year"
                       ng-change='convertModel()'>
        </md-datepicker>

        <br ng-if='!bigScreen'>

        <a ng-style=todayStyle ng-click='setToday()'>
          Today
        </a>

        <a style='padding-left:.5em;vertical-align:sub' ng-click='clear()'>
          Clear
        </a>
      </div>
    `
        };
    });
