/**
 * @name about
 * @ngdoc module
 * @description
 * About CHAI Module
 */


angular.module('qrcode', [])

    .service('qrCodeStore', function ($rootScope) {


        this.createQR = function(patientID ){
            var request = {
                patient_id: patientID,
                name: "My Emergency Passport",
                type: 2
            };

            return fetch($rootScope.qrServiceUrl+"/qr", {
                method: "POST",
                body: JSON.stringify(request),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(function (result) {
                return result.json();
            });

        };
    });
