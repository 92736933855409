import Keycloak from "keycloak-js";
import chaiApp from "./index";

fetch('config/config.json')
    .then(response => response.json())
    .then(config => {

        var keycloakConfig = {
            "url": config.keycloak,
            "realm": "chai",
            "clientId": "chai-frontend"
        };

        var keycloak = Keycloak(keycloakConfig);

        keycloak.init({
            onLoad: 'login-required'
        }).then(function () {

            chaiApp.run(function ($rootScope, $interval, $location, $http, $state, $timeout) {
                $rootScope.token = keycloak.token;
                $rootScope.clockSkew = keycloak.timeSkew;
                $rootScope.qrServiceUrl = config.qrServiceUrl;
                $rootScope.isAManager = false;
                $rootScope.isCommunityManager = false;
                $rootScope.isWardManager = false;
                $rootScope.locale = keycloak.tokenParsed.locale || 'en';
                if (keycloak.tokenParsed.management) {
                    keycloak.tokenParsed.management.forEach(role => {
                        if (role.startsWith('ward_')) {
                            $rootScope.isWardManager = true;
                        }
                        if (role.startsWith('community_')) {
                            $rootScope.isCommunityManager = true;
                        }
                    });
                    $rootScope.isAManager = $rootScope.isWardManager || $rootScope.isCommunityManager;

                    $rootScope.wardManagerOf = keycloak.tokenParsed.management.filter(group => {
                        return group.startsWith('ward_');
                    }).map(ward => {
                        let parts = ward.split('_');
                        return parts[1] + '_' + parts[2].toLowerCase();
                    });
                } else {
                    $rootScope.wardManagerOf = [];
                }
                $rootScope.keycloak = keycloak;


                $rootScope.updateToken = function () {
                    /*
                    refreshes the token iff there is more than 180 seconds left on the token.
                    We have 3 chances to renew before ending in a stupid place
                    */
                    $rootScope.clockSkew = keycloak.timeSkew;
                    var expireTime= (keycloak.tokenParsed.exp + keycloak.timeSkew)  * 1000;
                    if (keycloak.timeSkew !== 0) {
                        console.warn("WARNING: client clock is skewed by "+ keycloak.timeSkew + " seconds")
                    }
                    var currentTime=Date.now();
                    var okWindow=60*4*1000; // 60 4 minutes * 10000
                    if(expireTime+okWindow < currentTime)
                    {
                        $rootScope.logout();
                    }
                    else if(currentTime+okWindow > expireTime)
                    {
                        keycloak.updateToken(180).then(refreshed => {
                            $rootScope.token = keycloak.token;
                            $http.defaults.headers.common['Authorization'] = 'BEARER ' + keycloak.token;
                        }).catch(err => {
                            $rootScope.logout();
                        });
                    }
                };

                var updateTokenInterval = $interval(function () {
                        $rootScope.updateToken();
                    },
                    60000);

                    $rootScope.logoutSafe = function () {
                        $interval.cancel(updateTokenInterval);
                        const url = $location.absUrl();
                        keycloak.logout({ redirectUri: url.substring(0,url.lastIndexOf($location.url())+1)});
                    };
                $rootScope.logout = function () {
                    $interval.cancel(updateTokenInterval);
                    keycloak.logout();
                };


            }).config(function ($httpProvider) {

                $httpProvider.defaults.headers.common['Authorization'] = 'BEARER ' + keycloak.token;
            });

            angular.bootstrap(document, ['ad-chai'], {strictDi: true});
        });

    });
