module.exports = {
    url: '/passport/:type',
    controller: function ($scope, $rootScope, $window, LoggedInUser, $state) {
        'ngInject';
        $state.go('patient.passport', {id: LoggedInUser.getPatientId(), type: $state.params.type});

    },
    template: `
  <navigation-bar></navigation-bar>
  `
};
