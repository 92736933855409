module.exports = {
    url: '/shared_with_me',
    views: {
        main: {
            controller: function ($scope, $mdDialog, $mdToast, LoggedInUser,PassportShareStore) {
                'ngInject';
                $scope.patient = $scope.$parent.patient;
                $scope.shares = [];
                $scope.loading = true;
                $scope.currentNavItem = 'passports';

                var refreshPassports = function(){
                    $scope.loading = true;
                    PassportShareStore.passportsSharedWithUser(LoggedInUser.getUsername())
                        .then(resp => {
                            $scope.shares = resp;
                            $scope.loading = false;
                        });
                };

                refreshPassports();

                var patientId= $scope.$parent.patientId;
                $scope.shareClicked = function (ev,share){
                    $mdDialog.show({
                        controller: function ($scope, $mdDialog) {
                            $scope.cancel = function() {
                                $mdDialog.cancel();
                            };

                            $scope.share = share;

                            $scope.stopSharingClicked = function(){

                                var confirm = $mdDialog.confirm()
                                    .title('Remove Passport Access')
                                    .textContent('Are you sure you want to remove your access to the passports of '+share.firstName + ' ' + share.surname + '?')
                                    .ariaLabel('Revoke Access Dialog')
                                    .targetEvent(ev)
                                    .ok('Remove')
                                    .multiple(true)
                                    .cancel('Cancel');

                                $mdDialog.show(confirm).then(function () {
                                    PassportShareStore.removePassportShare($scope.share.patientId, LoggedInUser.getUsername())
                                        .then(function(){
                                            $mdToast.show({
                                                template: '<md-toast>Passport Access Removed</md-toast>',
                                                hideDelay: 2000,
                                                position: 'bottom center'
                                            });
                                            $mdDialog.hide('ok');
                                        });
                                }, function () {

                                });



                            };

                        },
                        template: require('../../../views/passport/view-shared-passport-dialog.tmpl.html').default,
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose:true,
                    }).then(function(ok){
                        refreshPassports();
                    }, function(){
                    });
                };

                $scope.newShareClicked= function(ev) {
                    $mdDialog.show({
                        controller: function ($scope, $mdDialog) {
                            $scope.cancel = function() {
                                $mdDialog.cancel();
                            };

                            $scope.input = {
                                username: "",
                                firstName: "",
                                lastName: ""
                            };

                            $scope.save = function() {
                                PassportShareStore.newPassportShare(patientId, $scope.input)
                                    .then(function(status){
                                        if (status === 409) {
                                            $mdToast.show({
                                                template: '<md-toast>Person Already Invited</md-toast>',
                                                hideDelay: 2000,
                                                position: 'bottom center'
                                            });
                                        }else {
                                            $mdToast.show({
                                                template: '<md-toast>Passport Shared</md-toast>',
                                                hideDelay: 2000,
                                                position: 'bottom center'
                                            });
                                        }
                                        $mdDialog.hide('ok');
                                    });
                            };

                            $scope.agreementsClicked = function(){
                                $mdDialog.hide();
                                goTo('agreements');
                            };

                        },
                        template: require('../../../views/passport/new-share-dialog.tmpl.html').default,
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose:true,
                    }).then(function(ok){
                        refreshPassports();
                    }, function(){
                    });
                };


                $scope.goto = function(page) {
                    $scope.currentNavItem=page;
                };

                var goTo = $scope.goto;

                $scope.showSharingHelp = function(ev) {
                    $mdDialog.show({
                        controller: function ($scope, $mdDialog) {
                            $scope.hide = function() {
                                $mdDialog.hide();
                            };

                            $scope.agreementsClicked = function(){
                                $mdDialog.hide();
                                goTo('agreements');
                            };

                        },
                        template: require('../../../views/passport/sharing-help.dialog.tmpl.html').default,
                        parent: angular.element(document.body),
                        targetEvent: ev,
                        clickOutsideToClose:true,
                    });

                };


            },
            template: `
                    <div ng-cloak>
                   <md-content>
                   
                    <div class="share-container">
                    <div ng-if="currentNavItem === 'agreements'">
                         <manage-access-codes patient="patient"></manage-access-codes>
                    </div>
                     
                     <div class="md-padding" ng-if="currentNavItem === 'passports'">
                        
 
                       <div ng-if="!loading && shares.length === 0 ">
                        <h2 style="text-align: center" class="md-title">Shared With Me</h2>
                        <p style="text-align: center">No one is currently sharing their passport with you. If you would like your friends or family members to share 
                        their health Passports with you, please invite them to join <a href="https://careandrespond.com">Care & Respond</a> </p>
                        <div layout="row" layout-align="center" style="padding: 2em">
                            <i class="fa-light fa-passport fa-6x" md-colors="{ 'color': 'primary-100'}"></i>
                        </div>
                        </div>
                        
                       <div ng-if="shares.length > 0">
                        <h2 class="md-title">Available Passports</h2>
                       <div ng-if="!loading">
                       {{shares.length }} {{shares.length == 1 ? 'person has' : 'people have' }}  shared their Passports with you.
                        </div>
                         <div ng-if='loading' layout layout-align='center center' layout-margin>
                        <i class='fas fa-spinner fa-spin fa-2x'>
                        </i>
                    </div>
                    <md-list ng-if="!loading">
                   <div ng-repeat="share in shares">
                    <md-list-item  class="md-3-line md-no-proxy force-center" style="padding:0" layout="row" layout-align="start center" ng-click="shareClicked($event,share)">
                        <div class="md-list-item-text" layout="column" flex ng-click="showViewShareDialog(id, share, $event)">
                        <h3 style="overflow: auto; white-space: normal">
                        {{ share.firstName }} {{ share.surname }}
                        </h3>
                        <h4>
                            {{ share.username }}
                        </h4>
                        </div>
                         <md-chips show-gt-xs hide-xs ng-if="share.passport === 'accepted'">
                            <md-chip>Active <i class="fa-solid fa-badge-check right" style="line-height: 32px; margin-left: 0.5em; color: green" >
                                                      <md-tooltip md-direction="bottom">Share Active</md-tooltip></i> </md-chip>
                            </md-chips>
                            <md-chips show-gt-xs hide-xs ng-if="share.passport === 'pending'">
                            <md-chip >Pending </md-chip>
                            </md-chips>
                          <i ng-if="share.passport === 'pending'" show-xs hide-gt-xs class="fa-solid fa-badge right" style="margin-right: 0.5em; color: #ACB3BE" >
                                                      <md-tooltip md-direction="bottom">Share Pending</md-tooltip>
                            </i>
                              <i ng-if="share.passport === 'accepted'" show-xs hide-gt-xs class="fa-solid fa-badge-check right" style="margin-right: 0.5em; color: green" >
                                                      <md-tooltip md-direction="bottom">Share Active</md-tooltip>
                            </i>
                        <i class="fa-light fa-chevron-right right fa-2xl"></i>
                      </md-list-item>
                      <md-divider></md-divider>
                      </div>
                    </md-list>
                </div>
                       </div>
</div>
                </md-content>
                </div>
          `
        },
        title: {
            controller: function () {
            },
            template: `
                Shared With Me
      `
        }
    }
};
