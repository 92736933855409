/**
 * @name fields
 * @ngdoc module
 * @description
 * All the fields
 */

angular.module('fields', ['ngFileUpload'])
    .controller('FieldController', function ($scope, cleverCards, Reject, Actions, $rootScope,
                                             MediaStore, PassportService, Utils, maxUpload, PastPatientObject,
                                             signOffInfo, $mdDialog) {

        this.$onInit = function () {

            if (!$scope.metaModel) {
                $scope.metaModel = {};
            }

            if ($scope.field.type === 'aggregate') {
                if ($scope.ngModel[$scope.field.name] === undefined) {
                    $scope.aggValuePrev = 0;
                } else {
                    if ($rootScope.signoffLater.value || $rootScope.signoff) {
                        const prev = angular.isNumber($scope.ngModel[$scope.field.value.field]) ?
                            $scope.ngModel[$scope.field.value.field] : 0;

                        $scope.aggValuePrev = $scope.ngModel[$scope.field.name] - prev;
                    } else {
                        $scope.aggValuePrev = $scope.ngModel[$scope.field.name];
                    }
                }
            }
        };

        var passportForms = PassportService.passportForms();

        $scope.showOnlyDaysRepeatPeriod = function () {
            const matchingForm = passportForms.find(passportForm => passportForm === $scope.formName);
            return matchingForm && matchingForm.length > 0;
        };

        $scope.showImage = function (image) {
            $mdDialog.show({
                parent: angular.element(document.body),
                locals: {image: image},
                clickOutsideToClose: true,
                template: `
            <md-dialog aria-label='Warning Details'>
              <div layout-align="center center" layout-margin layout-padding>
                <img http-src="{{image}}" />
              </div>
            </md-dialog>
          `,
                controller: function DialogController($scope, image) {
                    $scope.image = image;
                }
            });
        };

        $scope.maxUpload = maxUpload;

        $scope.instructionsExpanded = {value: false};

        $scope.updateDelay = {debounce: 250};

        $scope.serverUrl = $rootScope.serverUrl;
        $scope.trainee = $rootScope.trainee;

        if ($rootScope.signoff) {
            $scope.trainee = false;
        }

        $scope.allowNumbersOnly = function (e) {
            const allowedChars = '0123456789.';
            if (e.key.length === 1 && (allowedChars.indexOf(e.key) === -1)) {
                e.preventDefault();
            }

        };

        $scope.details = {};
        $scope.sending = false;

        $scope.focus = false;
        $scope.blur = false;

        let patient;

        if ($rootScope.signoffLater.value && (signOffInfo.assessment || signOffInfo.careplans)) {
            patient = PastPatientObject.value;
        } else {
            patient = $scope.patient;
        }

        if ($scope.field.hasOwnProperty('action')) {

            if ($scope.formType === 'static') {

                if (!$scope.field.hasOwnProperty('hook')) {
                    $scope.field.hook = {'event': 'submit', 'details': $scope.cardName};
                }

                Actions.transform($scope.field, {
                    group: $scope.groupIndex,
                    form: $scope.formName,
                    card: $scope.cardName
                });
            } else {

                if (!$scope.field.hasOwnProperty('hook')) {
                    $scope.field.hook = {'event': 'submit', 'details': $scope.formName};
                }
                Actions.transform($scope.field, {card: $scope.formName});
            }
        }

        /*
        * disable: true -> always hide
        * otherwise if expressions resolves to
        * true: hide
        * false: disable but don't hide
        * undefined: visible and enabled
        */
        $scope.evalDisable = function (expressions) {
            if (!$scope.metaModel[$scope.field.name]) {
                $scope.metaModel[$scope.field.name] = {};
            }

            if ($scope.field.hide_mychai || expressions === true) {
                $scope.metaModel[$scope.field.name].hidden = true;
                return true;
            }

            let result = cleverCards.evalDisable(expressions, patient, $scope.ngModel);

            if (result === true) {
                const field_value = $scope.ngModel[$scope.field.name];
                let toShow = Utils.isFieldEmpty(field_value) || ('value' in $scope.field) ? true : undefined;
                $scope.metaModel[$scope.field.name].hidden = toShow;
                return toShow;
            } else {
                return result;
            }

        };

        if ($scope.field.hasOwnProperty("value") && (
            $scope.field.type === 'multiple_checkboxes' ||
            $scope.field.type === 'radio_buttons' ||
            $scope.field.type === 'image_input')) {
            $scope.$watch('ngModel', function (oldVal) {
                // recalculates the derived field when the model changes
                $scope.evalDerived($scope.field.value);
            }, true);
        }



        $scope.evalAction = function (action) {
            $scope.alerts = cleverCards.evalAction(action,
                patient,
                $scope.ngModel);

            if ($scope.alerts && $scope.alerts.length > 0) {
                return true;
            }
        };

        $scope.evalDerived = function (expressions) {
            const value = cleverCards.evalDerived(expressions,
                patient,
                $scope.ngModel);

            if(!angular.equals($scope.ngModel[$scope.field.name] ,value)) {
                $scope.ngModel[$scope.field.name] = value;
            }

            return value;
        };

        //repeating specific
        $scope.evalAggregate = function (field) {
            const prev = $scope.aggValuePrev;
            const current = $scope.ngModel[field.value.field];
            $scope.ngModel[field.name] = (angular.isNumber(prev) ? prev : 0) + (angular.isNumber(current) ? current : 0);
            return $scope.ngModel[field.name];
        };


        $scope.check = function (value) {
            Reject.remove($scope.formName, 'self', 'self');
            if (!$scope.ngModel[$scope.field.name]) {
                $scope.ngModel[$scope.field.name] = {};
            }
            $scope.ngModel[$scope.field.name][value] =
                !$scope.ngModel[$scope.field.name][value];
        };
        /* STATIC version
         $scope.check = function(value) {
         if (!$scope.data[$scope.field.name]) {
         $scope.data[$scope.field.name] = {};
         }
         $scope.data[$scope.field.name][value] = !$scope.data[$scope.field.name][value];
         };*/


        $scope.inFocus = function () {
            Reject.remove($scope.formName, 'self', 'self');
            $scope.focus = true;
            $scope.blur = false;
        };

        $scope.sliderChanged = function() {
            Reject.remove($scope.formName, 'self', 'self');
        };

        $scope.inBlur = function () {
            $scope.focus = false;
            $scope.blur = true;
        };

        $scope.showWarning = function () {
            $mdDialog.show({
                parent: angular.element(document.body),
                locals: {details: $scope.details},
                clickOutsideToClose: true,
                template: `
          <md-dialog aria-label='Warning Details'>
            <div layout-align="center center" layout-margin layout-padding>
              <span ng-bind='details.message'></span>
            </div>
          </md-dialog>
        `,
                controller: function DialogController($scope, details) {
                    $scope.details = details;
                }
            });
        };

        $scope.signActionsDisabled = true;

        $scope.sendSig = function (signature) {
            const sign = Utils.dataURItoPNGblob(signature.dataUrl);
            if (!sign) {
                return;
            }
            MediaStore.postMedia('image/png', true).then(Media => {
                Media.send(sign, function (resp) {
                    $scope.ngModel[$scope.field.name] = resp.path;
                });
            });
        };

        $scope.uploadImage = function (image) {

            if (image) {
                $scope.imageError = false;
                $scope.sending = true;
                MediaStore.postMedia(image.type, true).then(Media => {
                    Media.send(image, function (resp) {
                        $scope.ngModel[$scope.field.name] = resp.path;
                        $scope.sending = false;
                    });
                });
            } else {
                $scope.imageError = true;
            }
        };

        //clever cards
        $scope.evalWarning = function (expressions) {
            let warning = cleverCards.evalWarning(expressions,
                patient,
                $scope.ngModel,
                $scope.details);
            if (!$scope.metaModel[$scope.field.name]) {
                $scope.metaModel[$scope.field.name] = {};
            }
            $scope.metaModel[$scope.field.name].warning = $scope.details;
            if (!warning) {
                $scope.metaModel[$scope.field.name].warning = {};
            }
            return warning;
        };

        $scope.evalReject = function (expressions) {
            return cleverCards.evalReject(expressions,
                patient,
                $scope.ngModel,
                $scope.formName,
                $scope.field.name,
                $scope.cardName,
                $scope.details);
        };

        $scope.toShowIcon = $scope.field.hasOwnProperty('reject') ||
            $scope.field.hasOwnProperty('warning') ||
            $scope.field.hasOwnProperty('info');

        $scope.shouldShowIcon = function () {

            if ($scope.evalReject($scope.field.reject)) {

                if ($scope.field.type === 'number_field' || $scope.field.type === 'text_input' ||
                    $scope.field.type === 'ews_name') {
                    $scope.details.css = {
                        'box-shadow': `-13px 13px 0 -12px ${$scope.details.color},
                                       13px 13px 0 -12px ${$scope.details.color}`
                    };
                } else if ($scope.field.type === 'text_area') {
                    $scope.details.css = {
                        'box-shadow': `-22px 22px 0 -21px ${$scope.details.color},
                                       22px 22px 0 -21px ${$scope.details.color}`
                    };
                }
                return true;
            } else if ($scope.evalWarning($scope.field.warning)) {

                if ($scope.field.type === 'number_field' || $scope.field.type === 'text_input') {
                    $scope.details.css = {
                        'box-shadow': `-13px 13px 0 -12px ${$scope.details.color},
                                       13px 13px 0 -12px ${$scope.details.color}`
                    };
                } else if ($scope.field.type === 'text_area') {
                    $scope.details.css = {
                        'box-shadow': `-22px 22px 0 -21px ${$scope.details.color},
                                       22px 22px 0 -21px ${$scope.details.color}`
                    };
                }
                return true;
            } else if ($scope.field.hasOwnProperty('info')) {
                $scope.details.message = $scope.field.info;
                $scope.details.icon = 'fa-info-circle';
                $scope.details.color = '#cccccc';
                return true;
            } else {
                return false;
            }
        };

    })
    .directive('cardField', function () {
        return {
            restrict: 'E',
            scope: {
                field: '=',
                ngModel: '=',
                metaModel: '=?',
                patient: '=',
                cardName: '=',
                formName: '=',
                formType: '@',
                groupIndex: '='
            },
            controller: 'FieldController',
            template: `
  <div class='field'
     ng-hide='evalDisable(field.disable) === true'>

    <div ng-if='field.type === "section"'>
        <div class='section-bg'></div>
    </div>

    <div class="relative">

      <!-- exclusion for checkbox as put label to right -->
      <div ng-class="{'field__expanded': instructionsExpanded.value}"
           ng-if='field.label && field.type !== "check_box"' class='row'>
        <label ng-class='{"focus": focus, "blur": blur}' chai-markdown='field.label'>
        </label>
        <accordion instructions-expanded="instructionsExpanded"
                   ng-if="field.instructions || field.instructions_type === 'video'" field="field">
        </accordion>
      </div>

      <div class='row' ng-if='field.hasOwnProperty("value")'>
          <div ng-if='field.type === "text_input" || field.type === "text_area"'
                chai-markdown='evalDerived(field.value)' class='derived'></div>

          <div ng-if='field.type === "number_field"'
                ng-bind='evalDerived(field.value)' class='derived'></div>

          <div ng-if='field.type === "check_box"'
                class='derived'>
              <div class="row">
              <div class='columns'>
                  <label chai-markdown='field.label'></label>
              </div>
                <div class='columns'>
                <span>{{ evalDerived(field.value) ? 'Yes' : 'No'}}</span>
              </div>
            </div>
          </div>
                
          <div ng-if='field.type === "phone_number_field"'
                ng-bind='evalDerived(field.value)' class='derived'></div>
                
        <div ng-if='field.type === "image_input"'  class='derived'>
         <span ng-click='showImage(serverUrl + ngModel[field.name])' class="large-6 small-6 columns"
                ng-if='field.type == "image_input" && ngModel[field.name]'>
            <img style='max-height: 4em;'
                 http-src="{{serverUrl + ngModel[field.name]}}" />
            <i class='fas fa-search-plus fa-stack-2x'></i>
          </span>
         <img alt="Input Image" ng-if=''
           http-src='{{serverUrl + ngModel[field.name]}}' class='image-input'/>
        </div>
 
          <div ng-if='field.type === "ews_score"'
                ng-bind='evalDerived(field.value)' class='derived'></div>

          <div ng-if='field.type === "ews_name"'
                ng-bind='evalDerived(field.value)' class='derived'></div>

          <div ng-if='field.type === "ews_colour"'
                ng-bind='evalDerived(field.value)' class='derived'></div>

          <div ng-if='field.type === "ews_date"'
                ng-bind='evalDerived(field.value)' class='derived'></div>

          <div ng-if='field.type === "ews_description"'
                ng-bind='evalDerived(field.value)' class='derived'></div>
                                
        <div ng-if='field.type === "radio_buttons" && ngModel[field.name] && ngModel[field.name].value'
             ng-bind='field.options[ngModel[field.name].value]' class='derived'>
        </div>
                
        <div ng-if='field.type === "multiple_checkboxes"' class='derived'>
         <ul ng-repeat='(value, name) in field.options'>
              <li ng-if="ngModel[field.name][value]" >
                  <span chai-markdown='name'></span>
              </li>
          </ul>
        </div>

          <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning", "unit-right"]'></i>

      </div>

      <div class='row aggregate' ng-if='field.type === "aggregate"'
                ng-bind='evalAggregate(field)'></div>

      <div ng-switch on='field.type' ng-if='!field.hasOwnProperty("value")'>
          <div ng-switch-when='text_input' class='row'>
              <input type='text'
                     placeholder='{{field.placeholder}}'
                     ng-style='details.css'
                     ng-focus='inFocus()'
                     ng-blur='inBlur()'
                     ng-model-options='updateDelay'
                     ng-model='ngModel[field.name]'
                     ng-required='{{field.required}}'
                     ng-disabled='evalDisable(field.disable) == false'
              />
              <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning", "unit-right"]'></i>
          </div>


          <div ng-switch-when='password' class='row'>
              <input type='password'
                     ng-style='details.css'
                     ng-focus='inFocus()'
                     ng-blur='inBlur()'
                     ng-model-options='updateDelay'
                     ng-model='ngModel[field.name]'
                     ng-required='{{field.required}}'
                     ng-disabled='evalDisable(field.disable) == false'
              />
          </div>


          <div ng-switch-when='number_field' class='row'>
              <input type='number'
                     step='any'
                     ng-keypress='allowNumbersOnly($event)'
                     ng-style='details.css'
                     ng-focus='inFocus()'
                     ng-blur='inBlur()'
                     ng-model-options='updateDelay'
                     ng-required='{{field.required}}'
                     ng-model='ngModel[field.name]'/>
              <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>

          <div ng-switch-when='radio_buttons'>
            <radio-button
                    ng-model='ngModel'
                    meta-model='metaModel'
                    form-name='formName'
                    focus='focus'
                    blur='blur'
                    field='field'>
            </radio-button>
                      <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>

          <div ng-switch-when='blood_pressure' class='row'>
              <label>Systolic: </label>
              <input type='number' class='short' ng-focus='inFocus()'
                     ng-blur='inBlur()' ng-model-options='updateDelay'
                     ng-model='ngModel[field.name].sys'/>
              <label>Diastolic: </label>
              <input type='number' class='short' ng-focus='inFocus()'
                     ng-blur='inBlur()' ng-model-options='updateDelay'
                     ng-model='ngModel[field.name].dia'/>
                      <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>

          <div ng-switch-when='phone_number_field' class='row'>
              <input
                      type='tel'
                      ng-focus='inFocus()'
                      ng-blur='inBlur()'
                      default-area-code="44"
                      name='{{field.name}}'
                      ng-model='ngModel[field.name]'
                      ng-required='{{field.required}}'
                      ng-disabled="evalDisable(field.disable) == false"/>
                       <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>
          
          <div ng-switch-when='slider' class='row'>
          
          <div layout="row" layout-xs="column" layout-align="space-between">
          
          <md-slider-container flex-gt-xs="85">
            <md-slider aria-label='Slider' class="md-primary"
                       ng-model='ngModel[field.name]'
                       ng-change='sliderChanged()'
                       min='{{ (field.ydomain && field.ydomain[0]) || 0 }}'
                       max='{{ (field.ydomain && field.ydomain[1]) || 100 }}'>
            </md-slider>
          </md-slider-container>
          
          <md-input-container flex-gt-xs="10" style="margin: 0">
            <input style="margin: 0" type="number" ng-model="ngModel[field.name]"
            min='{{ (field.ydomain && field.ydomain[0]) || 0 }}'
            max='{{ (field.ydomain && field.ydomain[1]) || 100 }}'
            ng-focus='inFocus()'
            ng-blur='inBlur()'
            aria-label="Slider Value">
          </md-input-container>
          
          <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'>
          </i>
          
          </div>
          
          </div>

          <div ng-switch-when='sign' class='row'>
              <img alt="signature" class='sign-display' ng-if='ngModel[field.name]'
                   http-src="{{serverUrl + ngModel[field.name]}}"/>

              <div class='sign'>
                <signature-pad accept="accept" clear="clear" height="180" width="360"
                               on-drawing="signActionsDisabled = false">
                </signature-pad>
              </div>

              <div layout='row'>

                <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                           aria-label='Clear Signature' style='margin-left: 0'
                           ng-disabled='signActionsDisabled'
                           ng-click="clear(); signActionsDisabled = true">
                  Clear
                </md-button>
                <md-button md-colors="{background: 'primary-600', color: 'primary-50'}"
                           aria-label='Accept Signature'
                           ng-disabled='signActionsDisabled'
                           ng-click="signature = accept(); sendSig(signature)">
                  Accept
                </md-button>

              </div>

          </div>

          <div ng-switch-when='audio_field'>

              <div class="audio-container audio-field">
                  <audio-input ward='false' src='ngModel[field.name]'></audio-input>
              </div>
              
          </div>

          <div ng-switch-when='check_box' class='row'>
              <div class='large-2 small-4 columns unit-left'>
                  <checkbox form-name='formName'
                            focus='focus'
                            blur='blur'
                            ng-model='ngModel[field.name]'>
                  </checkbox>
              </div>
              <div ng-class="{'field__expanded': instructionsExpanded.value}"
                   class='large-10 small-8 columns move-left'>
                  <label ng-class='{"focus": focus, "blur": blur}'
                         chai-markdown='field.label'></label>
                         
                  <accordion instructions-expanded="instructionsExpanded"
                             ng-if="field.instructions || field.instructions_type === 'video'"
                             field="field">
                  </accordion>
                  
                  <i ng-if='toShowIcon && shouldShowIcon()'
                     ng-style='{"color": details.color, "font-size": "1.7em"}'
                     ng-click='showWarning()'
                     ng-class='["fa", details.icon, "warning", "zero-top", "to-right"]'></i>
              </div>
          </div>

          <div ng-switch-when='multiple_checkboxes'>
              <div 
                   ng-repeat='(value, name) in field.options'
                   ng-click='check(value)'
                   layout="row">
                  <div class='right-padding'>
                      <i ng-class='["far",
                   {"fa-check-square fa-2x selected-true":
                      ngModel[field.name][value]},
                   {"fa-square fa-2x": !ngModel[field.name][value]}]'>
                      </i>
                  </div>
                  <div flex class='ml-checkbox'>
                      <label chai-markdown='name'></label>
                  </div>
              </div>
          </div>

          <div ng-switch-when='image_input'>
              <div class='row'>
                  <img alt="Input Image" ng-if='ngModel[field.name]'
                   http-src='{{serverUrl + ngModel[field.name]}}' class='image-input'/>

                <center>
                    <i ng-if='sending' class='fas fa-spinner fa-spin fa-2x topmargin'></i>
                </center>

                  <div class='small-3 columns'
                       accept='image/*'
                       ngf-select='uploadImage($file)' ngf-max-size="{{maxUpload}}">
                      <button class='button tiny'>
                          <i class='fas fa-camera'></i>
                      </button>
                  </div>
                  <i ng-show="imageError">File too big</i><br>

              </div>
          </div>
          <div ng-switch-when='date_field' class='row relative'>
              <date-picker form-name='formName'
                           focus='focus'
                           blur='blur'
                           disable='evalDisable(field.disable)'
                           ng-model='ngModel[field.name]'>
              </date-picker>
               <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>

          <div ng-switch-when='time_field' class='relative'>
              <time-picker form-name='formName'
                           focus='focus'
                           blur='blur'
                           ng-model='ngModel[field.name]'>
              </time-picker>
               <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>

          <div ng-switch-when='repeat_period'>
              <div class='row' ng-if="showOnlyDaysRepeatPeriod()">
                  Days: <input type='number' class='short'
                               ng-focus='inFocus()'
                               ng-blur='inBlur()'
                               ng-model='ngModel[field.name].due.days'/>
                 
              </div>
              <div class='row' ng-if="!showOnlyDaysRepeatPeriod()">
                  Days: <input type='number' class='short'
                               ng-focus='inFocus()'
                               ng-blur='inBlur()'
                               ng-model='ngModel[field.name].due.days'/>
                  Hours: <input type='number' class='short' ng-focus='inFocus()'
                                ng-blur='inBlur()'
                                ng-model='ngModel[field.name].due.hours'/>
                  Minutes: <input type='number' class='short' ng-focus='inFocus()'
                                  ng-blur='inBlur()'
                                  ng-model='ngModel[field.name].due.minutes'/>
              </div>
          </div>

          <div class='row' ng-switch-when='nhs_number_field'>
              <input
                      type='text'
                      nhs-number
                      name='{{field.name}}'
                      ng-focus='inFocus()'
                      ng-blur='inBlur()'
                      ng-model='ngModel[field.name]'
                      ng-required='{{field.required}}'
                      ng-disabled="evalDisable(field.disable) == false"
                      ng-if="!field.hasOwnProperty('value')"/>
                       <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>

          <div ng-switch-when='text_area'>
             <textarea
                     ng-model='ngModel[field.name]'
                     ng-model-options='updateDelay'
                     ng-focus='inFocus()'
                     ng-style='details.css'
                     ng-blur='inBlur()'
                     ng-required='{{field.required}}'
                     placeholder='{{field.placeholder}}'
                     ng-attr-disabled='!!field.disabled'>
                  </textarea>
                   <i ng-if='toShowIcon && shouldShowIcon()'
             ng-style='{"color": details.color, "font-size": "1.7em"}'
             ng-click='showWarning()'
             ng-class='["fa", details.icon, "warning",  "unit-right"]'></i>
          </div>
          
          <div ng-switch-when='range_field'>
              <div class='row'>
                <div class="columns small-5 medium-3">
                  Min: <input type='number' class='short'
                               ng-focus='inFocus()'
                               ng-blur='inBlur()'
                               ng-model='ngModel[field.name].min'/>
                </div>
                <div class="columns small-7 medium-9">                             
                               
                  Max: <input type='number' class='short' ng-focus='inFocus()'
                                ng-blur='inBlur()'
                                ng-model='ngModel[field.name].max'/>   
                </div>                               
              </div>
          </div>
      </div>

      <div ng-if='field.action && evalAction(field.action)' class='row action-alert'>
        <div ng-repeat='alert in alerts'>
          <i md-colors="{color: 'primary-600'}"
             class="fas fa-info-circle"></i>
          <span ng-bind='alert'></span>
        </div>
      </div>

    </div>
</div>
    `
        };

    }).directive('accordion', function (Utils, $timeout) {
    return {
        restrict: 'E',
        scope: {
            field: '=',
            instructionsExpanded: '='
        },
        controller: function ($scope) {
            if ($scope.field.video_id) {
                if ($scope.field.video_source === 'vimeo') {
                    $scope.videoExternalURL = 'https://vimeo.com/' + $scope.field.video_id;
                    $scope.videoEmbeddedURL = 'https://player.vimeo.com/video/' + $scope.field.video_id;
                } else {
                    $scope.videoExternalURL = 'https://www.youtube.com/watch?v=' + $scope.field.video_id;
                    $scope.videoEmbeddedURL = 'https://www.youtube.com/embed/' + $scope.field.video_id;
                }
            }

            $scope.expandInstructions = function () {
                $scope.instructionsExpanded.value = !$scope.instructionsExpanded.value;
            };

        },
        link: function (scope, element) {
            $timeout(function () {
                let elem = element.find('details');
                new Utils.accordion(elem[0]);
            }, 500);
        },
        template: `
                <details ng-if="field.instructions" ng-click="expandInstructions()">
                    <summary class="text_instructions">
                        {{ field.instructions_label || 'More Details'}}
                    </summary>
                    <div class="content">
                        <label chai-markdown='field.instructions'></label>
                    </div>
                </details>
                
                <details ng-if="field.instructions_type === 'video'">
                    <summary class="text_instructions">
                        {{ field.instructions_label || 'Watch the video'}}
                    </summary>
                    <div class="content">
                        <div style="padding:56.25% 0 0 0;position:relative;">
                            <iframe src="{{ videoEmbeddedURL | trustUrl }}"
                                    style="position:absolute;top:0;left:0;width:100%;height:100%;"
                                    frameborder="0" allow="fullscreen" allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                </details>
                
            `
    };
});
