/**
 * @name glyph
 * @ngdoc module
 * @description
 * The icon based images which represent a unique identity for each
 * patient.
 */
angular.module('glyphs', ['random'])

    /**
     * @name glyph
     * @ngdoc service
     * @description
     */
    .service('GlyphGenerator', function (Random) {
        this.create = function (seed, ranges) {
            let generator = Random(seed);
            return ranges.map(function (range) {
                return Math.ceil(generator() * range);
            });
        };
    })

    /**
     * @name cardGlyph
     * @ngdoc directive
     * @description
     */
    .directive('cardGlyph', function (GlyphGenerator) {
        return {
            restrict: 'E',
            scope: {
                seed: '='
            },
            link: function (scope) {
                scope.$watch('seed', function () {
                    if (scope.seed) {
                        let features = GlyphGenerator.create(scope.seed, [2, 7, 12]);
                        [scope.l1, scope.color, scope.l2] = features;
                    }
                });
            },
            template: `
      <div class='card-glyph' color='{{color}}' l1='{{l1}}' l2='{{l2}}'></div>
    `
        };
    })

    /**
     * @name glyph
     * @ngdoc directive
     * @restrict E
     * @description
     *
     */
    .directive('glyph', function ($rootScope, GlyphGenerator) {
        return {
            restrict: 'E',
            scope: {
                seed: '=',
                gender: '=',
                age: '=',
                ews: '=',
                ewsMap: '=',
                frailty: '=',
                size: '='
            },
            link: function (scope) {
                scope.noEws = true;
     
                scope.loaded = true;
                scope.useFrailtyGlyphs = $rootScope.useFrailtyGlyphs;
                scope.$watch('frailty.value', function () {
                    if (scope.frailty && scope.frailty.value) {
                        scope.frailtyScore = scope.frailty.value;
                    }
                });

                scope.$watch('seed', function () {
                    if (scope.seed) {
                        let features = GlyphGenerator.create(scope.seed, [4, 1]);
                        [scope.bg, scope.fg] = features;
                    }
                });

                scope.$watch('ewsMap', function () {
                    scope.noEws = (!scope.ewsMap.ews_colour);
                }, true);
            },
            template: `
      <div ng-style='{"color": ewsMap.ews_colour, "border-color": ewsMap.ews_colour}'
           ng-class='["glyph", size, {"no-ews": noEws}]'
                       bg='{{bg}}'
                       fg='{{fg}}'>

          <md-tooltip ng-if='ewsMap.ews_name' style='height:auto' md-direction='bottom'>
            <span ng-bind='ewsMap.ews_date'></span>
            <span ng-bind='ewsMap.ews_name'></span>
            <span ng-bind='ewsMap.ews_description'></span>
          </md-tooltip>
        <span class='pew' ng-bind='ews'></span>
        
        <div ng-if='useFrailtyGlyphs && loaded'>
            <i ng-if='frailtyScore == 1' class='icon icon-Frailty-01-NG'></i>
            <i ng-if='frailtyScore == 2' class='icon icon-Frailty-02-NG'></i>
            <i ng-if='frailtyScore == 3' class='icon icon-Frailty-03-NG'></i>
            <i ng-if='frailtyScore == 4' class='icon icon-Frailty-04-NG'></i>
            <i ng-if='frailtyScore == 5' class='icon icon-Frailty-05-NG'></i>
            <i ng-if='frailtyScore == 6' class='icon icon-Frailty-06-NG'></i>
            <i ng-if='frailtyScore == 7' class='icon icon-Frailty-07-NG'></i>
            <i ng-if='frailtyScore == 8' class='icon icon-Frailty-08-NG'></i>
            <i ng-if='frailtyScore == 9' class='icon icon-Frailty-09-NG'></i>
            <i ng-if='frailtyScore == 10' class='icon icon-Frailty-10-NG'></i>
            <i ng-if='frailtyScore == -1' class='icon icon-Death-NG'></i>

            <i class='fas fa-mars gender' ng-if='gender == "m"'></i>
            <i class='fas fa-venus gender' ng-if='gender == "f"'></i>          
        </div>
        <div ng-if='!useFrailtyGlyphs' && loaded>
            <i ng-if='age <= 2' class='icon icon-Baby-NoGender'></i>
            <i ng-if='age > 2 && age <= 5' class='icon icon-Toddler-NoGender'></i>
            <i ng-if='age > 5 && age < 18' class='icon icon-Child-NoGender'></i>
            
            <i class='fas fa-mars gender icon' ng-if='gender == "m"'></i>
            <i class='fas fa-venus gender icon' ng-if='gender == "f"'></i>
        </div>
      </div>
    `
        };
    });
