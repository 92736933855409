angular.module('walkthrough').run(function (Walkthrough) {
    const homeWalkthroughSteps = [
        {
            id: 'start',
            title: 'Welcome to Care & Respond',
            text: ['This Walkthrough will show you how to get started within Care & Respond.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'walkthrough_repeat',
            attachTo: {
                element: '.main-menu',
                on: 'bottom'
            },
            text: [`
                    You can access this walkthrough at any time from the Menu.
                `],
            buttons: [
                {
                    classes: 'shepherd-button-primary',
                    text: 'Start',
                    type: 'next'
                }
            ]
        },
        {
            id: 'personal_details',
            attachTo: {
                element: '#admissions_0_link',
                on: 'bottom'
            },
            title: 'Personal Details',
            scrollTo: {behavior: 'smooth', block: 'center'},
            text: ['To get the most out of Care & Respond we need to know information about you and your needs. This link takes you to the place to fill out this information. You can update this at any time.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'Health Details',
            attachTo: {
                element: '#admissions_1_link',
                on: 'bottom'
            },
            scrollTo: {behavior: 'smooth', block: 'center'},
            title: 'Health Details',
            text: [`Enter your health details here if you want to create one or more electronic health Passports which you can download/print and take with you or share through Care & Respond.`],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'connected_services',
            attachTo: {
                element: '#connected_services_link',
                on: 'top'
            },
            scrollToHandler: function (){
                console.log("scrolling");
                var top = document.getElementById("admissions_0_link").offsetTop-document.getElementsByClassName("main-nav")[0].offsetHeight-39;
                window.scrollTo(0, top);
            },
            title: 'Connected services',
            text: ['If you have been given a code by a health care service, social service, community group or any group you belong to that is connected to Care & Respond then you can enter it here to connect to that service.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'passports_summary',
            title: 'Create or Validate Passports',
            text: ['When you have completed My Profile and My Health you can Create and Validate your Passports. It is a good idea to Validate (check) that your Passports are up to date regularly, even if you have not made any changes. The last Validation date is stamped on your Passports.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'review_full_passport',
            attachTo: {
                element: '#review_passport_0_link',
                on: 'bottom'
            },
            scrollTo: {behavior: 'smooth', block: 'end'},
            title: 'Create/Validate Full Passport',
            text: ['Create or Validate your Full Passport here. Don’t forget to also create your Emergency Passport.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'review_emergency_passport',
            attachTo: {
                element: '#review_passport_1_link',
                on: 'bottom'
            },
            scrollTo: {behavior: 'smooth', block: 'end'},

            title: 'Create/Validate Emergency Passport',
            text: ['Your Emergency Passport contains only information about your health conditions that are important in an emergency. This link takes you to where you review your Emergency Passport. It is important that you confirm the content of both Passports.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },
        {
            id: 'passports_warning',
            title: '<i class="far fa-triangle-exclamation" style="color: orange; line-height: 18px; padding-right: 0.5em"></i>Remember',
            text: [`
                <p>Remember to re-Create and Validate your Passports every time you make a change to My Profile or My Health.
                We recommend you Validate your Passports regularly so that anyone reading them knows you try to keep them up to date.</p>
`],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ],
        },

        {
            id: 'view_passports',
            attachTo: {
                element: '#view_passports_container',
                on: 'bottom'
            },
            scrollToHandler: function (){
                var top = document.getElementById("review_passport_1_link").offsetTop-document.getElementsByClassName("main-nav")[0].offsetHeight-39;
                window.scrollTo(0, top);
            },
            title: 'View Current Passports',
            text: ['Your Validated Passports can be viewed here, and you can also see Passports that have been Shared with you.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ]
        },
        {
            id: 'view_passport_sharing',
            attachTo: {
                element: '#share_passport_link',
                on: 'bottom'
            },
            scrollToHandler: function (){
                var top = document.getElementById("share_passport_link").offsetTop-document.getElementsByClassName("main-nav")[0].offsetHeight-39;
                window.scrollTo(0, top);
            },
            title: 'Passport Sharing',
            text: ['You can share your Passport with named individuals by clicking on Passport Sharing. You can remove an individuals access to your Passport at any time.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ]
        },
        {
            id: 'view_supporters',
            attachTo: {
                element: '#my_supporters_link',
                on: 'bottom'
            },
            scrollToHandler: function (){
                var top = document.getElementById("my_supporters_link").offsetTop-document.getElementsByClassName("main-nav")[0].offsetHeight-39;
                window.scrollTo(0, top);
            },
            title: 'Supporters',
            text: ['Supporters can be setup to do more than just view your Passport. Click on My Supporters or People I Support to see what is available or coming soon.'],
            buttons: [
                {
                    classes: 'shepherd-button-secondary',
                    text: 'Skip',
                    type: 'cancel'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Back',
                    type: 'back'
                },
                {
                    classes: 'shepherd-button-primary',
                    text: 'Next',
                    type: 'next'
                }
            ]
        },
        {
            id: 'lets_go',
            title: 'Get Started Now',
            text: [`
                    <p>If you have any more questions then visit our FAQ <a href="https://careandrespond.com/guide/faq/">Here</a></p>
                `],
            buttons: [
                {
                    classes: 'shepherd-button-primary',
                    text: 'Ok',
                    type: 'next'
                }
            ]
        }];

    Walkthrough.registerWalkthrough("home", homeWalkthroughSteps);

});
