var FileSaver = require('file-saver');

module.exports = {
    url: '/',
    controller: function ($scope, Walkthrough, $translate, qrCodeStore, $rootScope, $window, $mdDialog, PatientStore, ProgressAggregator, LoggedInUser, CountersignStatus, RepeatingFormStore, FormStore) {
        'ngInject';

        $scope.$on('IdleStart', function () {
            $rootScope.updateToken();
        });
        $scope.$on('IdleEnd', function () {
            $rootScope.updateToken();
        });
        $scope.$on('IdleTimeout', function () {
            $rootScope.logout();
        });

        $translate.use($rootScope.locale);
        moment.locale($rootScope.locale);

        $window.document.title = 'Home';


        $scope.loaded = false;
        $scope.initPassport = false;

        $scope.admissions = $rootScope.admissionsForm;

        $scope.passports = [];

        $scope.showPassportText = function (passport) {
            $mdDialog.show({
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                template: `
                <md-dialog aria-label='Creating a Passport'>
                <md-dialog-content style='padding: 1em; color: #666'>
        
                  <div layout='row'>
                    <div flex='95' md-colors="{color: 'primary-300'}" style='font-size: 1.25em'>
                      <p>Before you can view/download your {{passportName}} you must:
                      <ol>
                      <li>Fill out your Health Details</li>
                      <li>Review the contents of your {{passportName}}</li>
                    </ol></p>
                    </div>
                    <div flex='5' style='text-align: end'>
                  
                      <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i>
                    </div>
                  </div>
       
                </md-dialog-content>
                </md-dialog>
              `,
                controller: function DialogController($scope, $mdDialog, $window) {
                    $scope.passportName = passport.name;
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }
            });
        };

        function checkPassportReviewed(passport) {
            RepeatingFormStore.load().then(DataStore => {
                return DataStore.valuesCount({
                    id: $scope.patientId,
                    card: passport.form,
                    count: 1,
                    offset: 0
                }).$promise.then(function (data) {
                    passport.hasCurrent = data && data.length > 0;
                    passport.currentLoaded = true;
                }, err => {
                    passport.hasCurrent = false;
                    passport.currentLoaded = true;
                });
            });
        }

        $scope.handleGenerateQRCode = function () {
            $mdDialog.show({
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                template: `
                <md-dialog aria-label='QR Code Generated'>
                <md-dialog-content style='padding: 1em; color: #666'>
                  
                   <div flex='4' style='text-align: end'>
                      <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i>
                    </div>
                 
                  <div layout="row" layout-align="center">
                      <i class='fas fa-spinner fa-spin fa-2x'>
                    </i>
                  </div>
       
                </md-dialog-content>
                </md-dialog>
              `,
                controller: function DialogController($scope, $mdDialog, $window) {
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }
            });
            qrCodeStore.createQR($scope.patientId).then(function (result) {
                $mdDialog.hide();
                $mdDialog.show({
                    parent: angular.element(document.body),
                    clickOutsideToClose: true,
                    template: `
                <md-dialog aria-label='QR Code Generated'>
                <md-dialog-content style='padding: 1em; color: #666'>
                   <div>
                   <div flex='12' style='text-align: end'>
                      <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i>
                    </div>
                    </div>
                  <div layout='column' >
                      <img ng-src="{{img_url}}" alt="Description" />
                      <div layout="row" layout-align="space-around">
                      <a  ng-click="onDownload()">Download</a>
                        </div>
                  </div>
                 
       
                </md-dialog-content>
                </md-dialog>
              `,
                    controller: function DialogController($scope, $mdDialog, $window) {
                        $scope.img_url = result.url;

                        $scope.onDownload = function () {

                            fetch($scope.img_url).then(
                                function (data) {
                                    {
                                        // console.log(data.arrayBuffer());
                                        data.arrayBuffer().then(function (bytes){
                                            var blob = new Blob([bytes], {type: "application/octet-stream"});
                                            FileSaver.saveAs(blob, "passport_qr.png");
                                            $scope.closeDialog();
                                        });

                                    }
                                }
                            );
                        };

                        $scope.closeDialog = function () {
                            $mdDialog.hide();
                        };
                    }
                });
            });


        };

        FormStore.loadForms().then(function (loadedForms) {
            $scope.patientId = LoggedInUser.getPatientId();
            $scope.patient = {id: $scope.patientId};

            $scope.forms = loadedForms.staticForms;
            $scope.accessCodeForm = loadedForms.accessCodeForm;
            $scope.customForms = loadedForms.customForms;
            $scope.trainee = loadedForms.trainee;

            $scope.countersignStatus = CountersignStatus.getStatus();
            $scope.fullPassport = loadedForms.repeatingForms.find(form => form.name === 'full_passport');
            $scope.emergencyPassport = loadedForms.repeatingForms.find(form => form.name === 'emergency_passport');
            if ($scope.fullPassport) {
                const fullPassport = {
                    name: 'Full Passport',
                    type: 'full',
                    form: 'full_passport',
                    currentLoaded: false,
                    hasCurrent: false,
                    icon: $scope.fullPassport.icon
                };
                $scope.passports.push(fullPassport);
                checkPassportReviewed(fullPassport);
            }
            if ($scope.emergencyPassport) {
                const emergencyPassport = {
                    name: 'Emergency Passport',
                    type: 'emergency',
                    form: 'emergency_passport',
                    currentLoaded: false,
                    hasCurrent: false,
                    icon: $scope.emergencyPassport.icon
                };
                $scope.passports.push(emergencyPassport);
                checkPassportReviewed(emergencyPassport);
            }
            PatientStore.load().then(Patient => {
                Patient.get({id: $scope.patientId}, function (patient) {
                    $scope.patient = patient;
                    ProgressAggregator.set(patient.progress);
                    $scope.loaded = true;
                    setTimeout(function () {
                        Walkthrough.showWalkthroughIfRequired('home');
                    }, 300);
                }, err => {
                });
            });
        });


    },
    title: "test",
    template: `
    <navigation-bar></navigation-bar>
    <title-bar>
      <div class='left'>
      </div>
      <div class='right'>
      </div>
    <div translate class="center">
        header_2
    </div>
    </title-bar>
    <main ng-if="loaded">
      <div class='row group-title'>
        <label chai-markdown='"Details"'></label>
      </div>
      
      <div class='patient data'>        
          <div class='fill'>
              <div id="admissions_{{$index}}_link" class='third' ng-repeat="group in admissions.groups">
                <a ui-sref='patient.group({id: patient.id, group: $index, form: "admissions", card:""})'>
                <div class='item'  >
                    <radial-progress progress-id="group.name">
                      <span>
                        <i ng-class='[group.icon, "fa-2x adjustright icon"]'>
                        </i>
                      </span>
                    </radial-progress>
                    <center>
                      <h6>{{group.label}}</h6>
                    </center>
                </div>
                </a>        
              </div>
              <div class='third' >
                <a id="connected_services_link" ui-sref='patient.group({id: patient.id, group: 0, form: "connected_services", card:"data_sharing_card"})'>
                <div class='item'>
                    <radial-progress progress-id="'ignore'">
                      <span>
                            <i ng-class='["fa-duotone fa-link", "fa-2x adjustright icon"]'> 
                        </i>
                      </span>
                    </radial-progress>
                    <center>
                      <h6>Connected Services</h6>
                    </center>
                </div>
                </a>        
              </div>              
          </div>
      </div>

      <div class='row group-title' ng-if="fullPassport || emergencyPassport">
        <label chai-markdown='"Create or Validate Passports"'></label>  
      </div>
      <div class='patient data'>        
        <div class='fill'>
           <div id="review_passport_{{$index}}_link"class='third' ng-repeat="passport in passports"> 
              <a ui-sref='patient.passport({id: patient.id, type: passport.type})'> 
              <div class='item'> 
                <span class="non-radial"> 
                  <i ng-class='["far fa-list-check", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                  <center> 
                    <h6>Validate {{passport.name}}</h6> 
                  </center> 
              </div> 
              </a>         
            </div> 
        </div>
      </div>
      
      <div class='row group-title' ng-if="fullPassport || emergencyPassport">
        <label chai-markdown='"Quick Share Passport"'></label>  
      </div>
      <div class='patient data'>        
        <div class='fill'>
           <div id="generate_qr_link" class='third' > 
              <a href="/qr"> 
              <div class='item'> 
                <span> 
                  <i ng-class='["far fa-qrcode", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                  <center> 
                    <h6>Create QR Code</h6> 
                  </center> 
              </div> 
              </a>         
            </div> 
        </div>
      </div>
      
      <div class='row group-title'>
        <label chai-markdown='"View Current Passports"'></label>
      </div>
      <div class="patient data" id="view_passports_container">

      <div class="fill" >
      <div class='third'  ng-repeat='passport in passports'>
        <div ng-if='!passport.currentLoaded' layout layout-align='center center' layout-margin>
            <i class='fas fa-spinner fa-spin fa-2x'>
            </i>
        </div>
        <div ng-if='!passport.hasCurrent' layout layout-align='center center' layout-margin>
            <div class='item' >            
                <div style="margin: 0 auto; position: relative; text-align: center">
                  <span class="md-caption non-radial" ng-click="showPassportText(passport)">
                  <i class="fa-regular fa-triangle-exclamation"></i>
                    You have not Created and/or Validated this passport yet.      
                  </span>
                  </div>            
                <center>
                  <h6>{{passport.name}}</h6>
                </center>
            </div>
        </div>
        <print ng-if="passport.currentLoaded && passport.hasCurrent" name='passport.form' patient-id='patient.id' type='"custom"'>
            <div class='item' >            
                <div style="width: 5em; margin: 0 auto; position: relative; text-align: center">
                  <span class="non-radial">
                    <i ng-class='[passport.icon, "fa-2x adjustright icon"]'>
                    </i>                
                  </span>
                  </div>            
                <center>
                  <h6>View {{passport.name}}</h6>
                </center>
            </div>
            </print>   
          
          </div>
           <div class='third'> 
              <a ui-sref='patient.sharedwithme({id: "me"})'>    
             <div class='item'> 
                <span class="non-radial"> 
                  <i ng-class='["fa-thin fa-rectangle-history-circle-user", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                  <center> 
                    <h6>Shared With Me</h6> 
                  </center> 
              </div> 
              </a></div>
        </div>
        </div>
        <div class='row group-title'>
        <label chai-markdown='"Support Network"'> </label>  
      </div>
      <div class='patient data'>        
        <div class='fill'>
        <div  id ="my_supporters_link" class="shepherd-container"></div>
           <div class='third'> 
              <a href='/support/en/supporters'> 
              <div class='item'> 
                <span class="non-radial"> 
                  <i ng-class='["fa-duotone fa-users", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                  <center> 
                    <h6>My Supporters</h6> 
                  </center> 
              </div> 
              </a>         
            </div> 
            <div class='third'> 
              <a href='/support/en/supporting'> 
              <div class='item'> 
                <span class="non-radial"> 
                  <i ng-class='["far fa-hand-holding-heart", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                  <center> 
                    <h6>People I Support</h6> 
                  </center> 
              </div> 
              </a>         
            </div> 
            <div class='third' id="share_passport_link"> 
            <a ui-sref='patient.sharepassport({id: patient.id})'>
              <div class='item'> 
                <span class="non-radial"> 
                  <i ng-class='["far fa-share-from-square", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                  <center> 
                    <h6>Passport Sharing</h6> 
                  </center> 
              </div> 
              </a>         
            </div> 
        </div>
      </div>

      <!-- Begin Video Edit -->
      <div class='row'>
       &nbsp;
      </div>
      <div class='patient data'>
        <div class='fill'>
          
            <a ui-sref='video'> 
              <div class='item'> 
                <span class="non-radial"> 
                  <i ng-class='["fas fa-film", "fa-2x adjustright icon"]'> 
                  </i> 
                </span> 
                <center> 
                  <h6>Short Films about Health</h6> 
                </center> 
              </div> 
            </a>         
          
        </div>
      </div>
      <!-- End Video Edit -->
         
      
      
    </main>
  `
};
