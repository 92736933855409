/**
 * @name progress
 * @ngdoc module
 * @description
 * A collection of progress measuring directives.
 */
angular.module('progress', [])

    /**
     * @name ProgressAggregator
     * @ngdoc service
     * @restrict E
     * @description
     * A service for keeping track of progress across
     * the dependency tree.
     */
    .service('ProgressAggregator', function () {
        this.nodes = {};
        this.set = function (progress) {
            this.nodes = {};
            for (var i in progress) {
                this.nodes[i] = progress[i].overall;
                delete progress[i].overall;
                for (var j in progress[i]) {
                    this.nodes[j] = progress[i][j];
                }
            }
        };

        this.update = function (formName, progress) {
            this.nodes[formName] = progress[formName].overall;
            delete progress[formName].overall;
            for (var i in progress[formName]) {
                this.nodes[i] = progress[formName][i];
            }
        };

        this.progress = function (name) {
            return this.nodes[name] || 0;
        };
    })

    .filter('cardNames', function () {
        return function (cards) {
            return cards.map(card => card.name);
        };
    })

    /**
     * @name radialProgress
     * @ngdoc directive
     * @restrict E
     * @description
     * A radial progress meter.
     */
    .directive('radialProgress', function (ProgressAggregator, $mdColors) {
        return {
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                name: '=progressId'
            },
            template: `
      <div class='radial progress'>
        <ng-transclude></ng-transclude>
      </div>
    `,
            link: function (scope, element) {
                let canvas = document.createElement('canvas');
                let context = canvas.getContext('2d');
                let domElement = element[0];

                canvas.height = domElement.clientHeight;
                canvas.width = domElement.clientWidth;

                var progressStroke = $mdColors.getThemeColor('primary-700');
                var bgStroke = $mdColors.getThemeColor('primary-800');

                function draw(start, end) {
                    let lineWidth = 10;
                    let radius = canvas.width / 2 - lineWidth;
                    let startRadians = start * (Math.PI * 2) - (Math.PI / 2);
                    let endRadians = end * (Math.PI * 2) - (Math.PI / 2);
                    let x = canvas.width / 2;
                    let y = canvas.height / 2;


                    radius = (radius < 0) ? 0 : radius;
                    context.beginPath();
                    context.arc(x, y, radius, startRadians, endRadians, false);
                    context.stroke();
                }

                function update() {
                    scope.progress = ProgressAggregator.progress(scope.name);
                    context.clearRect(0, 0, canvas.width, canvas.height);
                    context.lineWidth = 8;
                    context.strokeStyle = bgStroke;
                    draw(0, 1);
                    context.strokeStyle = progressStroke;
                    draw(0, scope.progress);
                }

                update();
                element.append(canvas);
            }
        };
    });
