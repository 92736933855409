module.exports = {
    url: '/video',
    controller: function ($scope, $rootScope, $mdDialog, $window) {   
        
        'ngInject';

        $window.document.title = 'CHAI Videos';
        $scope.label = 'Films Library';

    },


    template: `
    <navigation-bar></navigation-bar>

    <title-bar>
      <center>
        {{ label }}
      </center>
    </title-bar>

    <main>
            
      <videos></videos>
    </main>

  `
};
