module.exports = {
    url: '/patient/:id',
    controller: function ($scope, $state, PatientStore, PatientDecorator, $rootScope,
                          ProgressAggregator, problemIdentified, PatientAlerts, LoggedInUser,
                          CountersignStatus, CardsToReset) {
        'ngInject';
        let patientId = $state.params.id;

        if (patientId === 'me') {
            patientId = LoggedInUser.getPatientId();
        }
        $scope.$on('IdleTimeout', function () {
            $rootScope.logout();
        });

        $rootScope.patientId = patientId;
        $scope.fullName = $rootScope.fullName;
        $scope.signoff = $rootScope.signoff;
        $scope.signoffLater = $rootScope.signoffLater;

        CardsToReset.value = {};

        if ($scope.signoff) {
            $scope.fullName = $rootScope.fullName;
        }

        PatientStore.load().then(Patient => {
            Patient.get({id: patientId}, function (patient) {
                $scope.patient = patient;
                ProgressAggregator.set(patient.progress);
                PatientAlerts.refresh(patient.alerts);
                CountersignStatus.refresh(patient.approvals);
                if (patient.data.ews_map) {
                    if (patient.data.ews_map.ews_name) {
                        $rootScope.ewsType = patient.data.ews_map.ews_name.toLowerCase();
                    }
                    $rootScope.ews = patient.data.ews_map.ews_score;
                }
                PatientDecorator.addCardFunctions(patient);
                if ($scope.patient.concerns === undefined) {
                    $scope.patient.concerns = {};
                }
                for (var i in problemIdentified) {
                    if (!$scope.patient.concerns.hasOwnProperty(i)) {
                        delete problemIdentified[i];
                    }
                }
                for (var problem in $scope.patient.concerns) {
                    problemIdentified[problem] = $scope.patient.concerns[problem];
                }
                $scope.$watch(function () {
                    return problemIdentified;
                }, function () {
                    $scope.patient.concerns = problemIdentified;
                }, true);
            }, err => {
            });
        });

    },
    template: `
    <navigation-bar></navigation-bar>
    
    <title-bar>
        <center>
        <span hide-xs show-gt-xs><span class='name'
           ng-bind="patient.data.admissions.patient_details.first_name">
        </span>
        <span class='name'
           ng-bind="patient.data.admissions.patient_details.last_name">
         </span> : </span><span ui-view='title'></span>
        </center>
    </title-bar>    
   
    <main ng-if='patient'>    
<!--      <div ng-class='["patient-summary patient-summary&#45;&#45;dash",-->
<!--                     {"nodisplay": (signoff || signoffLater.value)}]'>-->
<!--        <patient-preview patient='patient' type='"large"'></patient-preview>-->
<!--      </div>-->
      <div ui-view='main'>
      </div>
    </main>
  `
};
