import Shepherd from "shepherd.js";

/**
 * @name passports
 * @ngdoc module
 * @description
 * Passport specific services and functionality
 */

angular.module('walkthrough', [])
    .service('WalkthroughService', function () {

        const service = this;
        service.confirmCancel = false;
        service.confirmCancelMessage = null;
        service.defaultStepOptions = {
            cancelIcon: {
                enabled: true
            },
            highlightClass: 'highlight',
            scrollTo: true,
            canClickTarget: false,
            arrow: false
        };
        service.errorTitle = null;
        service.isActive = false;
        service.keyboardNavigation = true;
        service.messageForUser = null;
        service.modal = true;
        service.requiredElements = [];
        service.tourName = undefined;
        service.tourObject = null;


        service.back = function () {
            service.tourObject.back();
        };

        /**
         * Cancel the tour
         */
        service.cancel = function () {
            service.tourObject.cancel();
        };

        /**
         * Complete the tour
         */
        service.complete = function () {
            service.tourObject.complete();
        };

        /**
         * Hides the current step
         */
        service.hide = function () {
            service.tourObject.hide();
        };

        /**
         * Advance the tour to the next step
         */
        service.next = function () {
            service.tourObject.next();
        };

        /**
         * Show a specific step, by passing its id
         * @param id The id of the step you want to show
         */
        service.show = function (id) {
            service.tourObject.show(id);
        };

        /**
         * Start the tour
         */
        service.start = function () {
            service.isActive = true;
            service.tourObject.start();
        };

        /**
         * This function is called when a tour is completed or cancelled to initiate cleanup.
         * @param completeOrCancel 'complete' or 'cancel'
         */
        var onTourFinish = function (completeOrCancel) {
            service.isActive = false;
        };

        service.onTourFinish = function (tourFinishFn) {
            service.tourObject.on('complete', tourFinishFn.bind(this, 'complete'));
            service.tourObject.on('cancel', tourFinishFn.bind(this, 'cancel'));
        };

        function _initialize() {
            const tourObject = new Shepherd.Tour({
                confirmCancel: service.confirmCancel,
                confirmCancelMessage: service.confirmCancelMessage,
                defaultStepOptions: service.defaultStepOptions,
                keyboardNavigation: service.keyboardNavigation,
                tourName: service.tourName,
                useModalOverlay: service.modal
            });

            tourObject.on('complete', onTourFinish.bind(this, 'complete'));
            tourObject.on('cancel', onTourFinish.bind(this, 'cancel'));

            service.tourObject = tourObject;
        }

        function elementIsHidden(element) {
            return element.offsetWidth === 0 && element.offsetHeight === 0;
        }

        /**
         * Observes the array of requiredElements, which are the elements that must be present at the start of the tour,
         * and determines if they exist, and are visible, if either is false, it will stop the tour from executing.
         */
        function requiredElementsPresent() {
            let allElementsPresent = true;

            /* istanbul ignore next: also can't test this due to things attached to root blowing up tests */
            service.requiredElements.forEach((element) => {
                const selectedElement = document.querySelector(element.selector);

                if (allElementsPresent && (!selectedElement || elementIsHidden(selectedElement))) {
                    allElementsPresent = false;
                    service.errorTitle = element.title;
                    service.messageForUser = element.message;
                }
            });

            return allElementsPresent;
        }

        function makeButton(button) {
            const {classes, disabled, label, secondary, type, text} = button;
            const builtInButtonTypes = ['back', 'cancel', 'next'];

            if (!type) {
                return button;
            }

            if (builtInButtonTypes.indexOf(type) === -1) {
                throw new Error(`'type' property must be one of 'back', 'cancel', or 'next'`);
            }

            return {
                action: this[type].bind(this),
                classes,
                disabled,
                label,
                secondary,
                text
            };
        }


        /**
         * Take a set of steps and create a tour object based on the current configuration
         * @param steps An array of steps
         */
        service.addSteps = function (steps) {
            _initialize();
            const tour = service.tourObject;

            // Return nothing if there are no steps
            if (!steps || !Array.isArray(steps) || steps.length === 0) {
                return;
            }

            if (!requiredElementsPresent()) {
                tour.addStep({
                    buttons: [{
                        text: 'Exit',
                        action: tour.cancel
                    }],
                    id: 'error',
                    title: service.errorTitle,
                    text: [service.messageForUser]
                });
                return;
            }

            steps.forEach((step) => {
                if (step.buttons) {
                    step.buttons = step.buttons.map(makeButton.bind(this), this);
                }

                tour.addStep(step);
            });
        };


    })
    .service('Walkthrough', function (LoggedInUser, WalkthroughService, Server) {
        const walkthroughMap = {};
        const service = this;


        const loadWalkthroughPreferences = function () {
            return Server.createResource(`/users/:username/prefs/walkthrough`, {
                updatePrefs: {
                    method: 'put',
                    url: `/users/:username/prefs/walkthrough`
                },
            });
        };

        const markWalkthroughCompleted = function (walkthroughName) {
            loadWalkthroughPreferences().then(function (PrefStore) {
                var newPrefs = LoggedInUser.getWalkThroughPrefs();
                newPrefs[walkthroughName] = true;
                LoggedInUser.setWalkThroughPrefs(newPrefs);
                PrefStore.updatePrefs({username: LoggedInUser.getUsername()}, newPrefs, function () {
                });
            });
        };

        service.showWalkthroughIfRequired = function (walkthroughName) {
            if (LoggedInUser.shouldShowWalkThrough(walkthroughName)) {
                service.showWalkthrough(walkthroughName);
            }
        };

        service.showWalkthrough = function (walkthroughName) {
            if (walkthroughMap[walkthroughName]) {
                WalkthroughService.addSteps(walkthroughMap[walkthroughName]);
                WalkthroughService.onTourFinish(function (event) {
                    markWalkthroughCompleted(walkthroughName);
                });
                WalkthroughService.start();
            }
        };

        service.registerWalkthrough = function (name, steps) {
            walkthroughMap[name] = steps;
        };

    });
