module.exports = {
    url: '/careplans/:card',
    views: {
        main: {
            controller: function ($scope, $rootScope, CarePlansStore, $state,
                                  expandCards, $window, CountersignStatus, signOffInfo, $timeout,
                                  PatientAlerts, Animate, expandedEntities, $location, $anchorScroll,
                                  $mdDialog) {
                'ngInject';

                if ($rootScope.type === 'pro') {
                    $scope.carePlansLabel = 'Care Plans';
                } else {
                    $scope.carePlansLabel = 'Health Diaries';
                }
                $scope.$on('IdleTimeout', function () {
                  $rootScope.logout();
                });
                $window.document.title = $scope.carePlansLabel;
                $rootScope.inactiveCareplan = false;
                $scope.countersignStatus = CountersignStatus.getStatus();
                $scope.signoff = $rootScope.signoff;
                $scope.canSubmitForms = $rootScope.canSubmitForms;
                $scope.signoffLater = $rootScope.signoffLater;

                expandedEntities.static = {};
                expandedEntities.repeating = {};

                $scope.data = {};
                $scope.patient = $scope.$parent.patient;
                $scope.patientId = $scope.patient.id;
                $scope.careplans = $scope.patient.careplans;
                $scope.formState = {};
                $scope.sortedRepForms = [];
                var noStateRepForms = [];

                $rootScope.carePlans.forEach(form => {
                    $scope.formState[form.name] = form;
                    $scope.formState[form.name].state = {};
                    if ($scope.patient.alerts && $scope.patient.alerts.careplans &&
                        $scope.patient.alerts.careplans[form.name]) {
                        PatientAlerts.setRepFormState($scope.patient.alerts.careplans[form.name],
                            $scope.formState[form.name].state);
                    }

                    form.display = !(($scope.signoff || $scope.signoffLater.value) && signOffInfo.form !== form.name);

                    if ($scope.formState[form.name].state.state === undefined) {
                        noStateRepForms.push(form);
                    }
                    $scope.data[form.name] = {};
                });

                function filterAndSortRepForms(forms, value) {
                    var filteredForms = [];
                    for (var form in forms) {
                        if (forms[form].state.state === value) {
                            filteredForms.push(forms[form]);
                        }
                    }
                    filteredForms.sort(function (a, b) {
                        return a.state.date > b.state.date ? 1 : -1;
                    });
                    return filteredForms;
                }

                var OverdueRepForms = filterAndSortRepForms($scope.formState, 'overdue');
                var WarnRepForms = filterAndSortRepForms($scope.formState, 'warn');
                var DueRepForms = filterAndSortRepForms($scope.formState, 'due');
                var CompletedRepForms = filterAndSortRepForms($scope.formState, 'completed');

                $scope.sortedRepForms.push(...OverdueRepForms,
                    ...WarnRepForms,
                    ...DueRepForms,
                    ...CompletedRepForms,
                    ...noStateRepForms);


                expandCards[$state.params.card] = true;

                var confirmDeactivation = function (form) {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        locals: {careplans: $scope.careplans, patient: $scope.patient},
                        template: `
          <md-dialog>
          <md-dialog-content>
          <div class='ts-dialog'>
            <div layout class='section'>
              <div translate translate-value-label="{{carePlansLabel}}"
                   style='padding-left: 0' flex='95' class='heading'>
                   careplan_2
              </div>
              <div flex>
                <i class='fas fa-times close-dialog' ng-click='cancel()'></i>
              </div>
            </div>

            <div layout='column' class='section'>
              <div translate translate-value-label="{{carePlansLabel}}">
                careplan_1
              </div>
              <div>
              <textarea class='reason' ng-model='reason'>
              </textarea>
              </div>
            </div>

            <div class='section' layout='row' layout-xs='column'
                 layout-margin layout-fill layout-align='end'>
              <button translate style='margin-right: 0' ng-disabled='reason == ""'
                      ng-click='updateCareplans()'>ok</button>
              <button translate style='margin-right: 0'
                      class='cancel'
                      ng-click='cancel()'>cancel</button>
            </div>

          </div>
          </md-dialog-content>
          </md-dialog>
        `,
                        controller: function DialogController($scope, $mdDialog, CarePlansStore, Messages,
                                                              $rootScope, careplans, patient) {

                            $scope.careplans = careplans;
                            $scope.patient = patient;
                            $scope.updateCareplans = function () {
                                CarePlansStore.load().then(CarePlan => {
                                    CarePlan.update({id: $rootScope.patientId, form: form},
                                        {value: false},
                                        data => {
                                            $scope.patient.last_update = data.last_update;
                                            let careplanLabel;
                                            $rootScope.carePlans.find(careplan => {
                                                if (careplan.name == form) {
                                                    careplanLabel = careplan.label;
                                                    return true;
                                                }
                                            });
                                            let messageContent = {};
                                            let reasonPrefix = 'Careplan ' + careplanLabel + ' turned off. Reason: ';
                                            messageContent.data = reasonPrefix + $scope.reason;
                                            messageContent.type = 'generated';
                                            Messages.send($rootScope.patientId, messageContent);
                                            $scope.closeDialog();
                                        }, error => {
                                        });
                                });
                            };

                            $scope.reason = '';

                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };

                            $scope.cancel = function () {
                                $scope.careplans[form] = !$scope.careplans[form];
                                $scope.closeDialog();
                            };


                        }
                    });
                };


                $scope.cannotDeactivateDialog = function () {
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        template: `
              <md-dialog aria-label='Warning Details'>
                <div layout-align="center center" layout-margin layout-padding>



        <div style='margin-top: 3em' class='row'>
          <div class='small-12 columns center brilliant-sky'>
            <i style='font-size: 7em' class='icon2-unapproved'></i>
          </div>
        </div>
        <div class='row'>
          <div class='small-12 columns careplan-modal-heading noleftpadding'>
            Countersign {{ carePlansLabel }}
          </div>
        </div>
        <div class='row'>
          <div class='small-12 columns noleftpadding'>
            <p class='careplan-modal-subtext'>
               This careplan has outstanding
               entries that need to be countersigned
            </p>
            <p class='careplan-modal-subtext'>
              Please countersign all these entries
              before attempting to deactivate this careplan
            </p>
          </div>
        </div>
        <div class='row center'>
          <button class='small careplan-modal-button' ng-click='closeDialog()'>
            OK
          </button>
        </div>



                </div>
              </md-dialog>
            `,
                        controller: function DialogController($scope, $mdDialog) {
                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }
                    });
                };

                $scope.handleCareplanUpdate = function (form) {

                    if (!$scope.careplans[form] && $scope.countersignStatus.careplans[form]) {
                        $scope.careplans[form] = !$scope.careplans[form];
                        $scope.cannotDeactivateDialog();
                        return;
                    }

                    if (!$scope.careplans[form]) {
                        confirmDeactivation(form);
                        delete expandedEntities.repeating[form];
                    } else {
                        CarePlansStore.load().then(CarePlan => {
                            CarePlan.update({id: $scope.patientId, form: form},
                                {value: true},
                                data => {
                                    $scope.patient.last_update = data.last_update;
                                }, error => {
                                });
                        });
                    }
                };

                $scope.scrollDown = function () {

                    $timeout(function () {
                        $location.hash('past-careplans');
                        $anchorScroll();
                    });
                };

            },
            template: `
      <div translate translate-value-label="{{carePlansLabel}}"
            class='careplan-heading' ng-if='!signoff && !signoffLater.value'>
            careplan_3
      </div>
      <div class="assessment">
        <repeating-form
           ng-repeat='activePlan in sortedRepForms'
           ng-if='careplans[activePlan.name] && activePlan.display'
           patient='patient'
           form='activePlan'>
        </repeating-form>
      </div>
      

      `
        },
        title: {
            controller: function ($scope, $rootScope) {
                'ngInject';
                if ($rootScope.type === 'pro') {
                    $scope.carePlansLabel = 'Care Plans';
                } else {
                    $scope.carePlansLabel = 'Health Diary';
                }
            },
            template: `
        {{ carePlansLabel }}
      `
        }
    }
};
