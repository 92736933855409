/**
 * @name about
 * @ngdoc module
 * @description
 * About CHAI Module
 */


angular.module('about', [])

    .service('aboutStore', function ($resource) {
        this.chai = function () {
            return $resource('build.json').get().$promise;
        };

        this.card = function () {
            return $resource('card_info.json').get().$promise;
        };

        this.compose = function () {
            return $resource('compose_info.json').get().$promise;
        };

        this.print = function () {
            return $resource('print/static/version.json').get().$promise;
        };

        this.backend = function () {
            return $resource('version').get().$promise;
        };

        this.config = function () {
            return $resource('config/config.json').get().$promise;
        };
    })

    .controller('AboutController', function ($scope, $mdDialog, Server) {
        $scope.faqSite = "";

        Server.getConfig().then(function (config) {
            $scope.faqSite = config.faqSite;
            $scope.suggestionSite = config.suggestionSite;
            $scope.productName = config.productName;
            $scope.guide = config.guide;
        });

        $scope.showTandCDialog = function () {

            $mdDialog.show({
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                template: `
        <md-dialog aria-label='{{productName}} Terms and Conditions Dialog'>
        <md-dialog-content style='padding: 1em; color: #666'>

          <div layout='row'>
            <div flex='95' md-colors="{color: 'primary-300'}" style='font-size: 1.25em'>
            </div>
            <div flex='5' style='text-align: end'>
              <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i>
            </div>
          </div>

          <div ng-include="'config/disclaimer.html'"></div>
        </md-dialog-content>
        </md-dialog>
      `,
                controller: function DialogController($scope, $mdDialog, $window) {
                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }
            });
        };

        $scope.showAboutCHAIDialog = function () {

            $mdDialog.show({
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                template: `
        <md-dialog aria-label='About CHAI Dialog'>
        <md-dialog-content style='padding: 1em; color: #666'>

    <div class='about-panel'>
    <div layout='row' layout-padding='1em'>
      <div flex='95' md-colors="{color: 'primary-300'}" style='font-size: 1.5em'>
        Help with using {{productName}}
      </div>
      <div flex='5' style='text-align: end'>
        <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i>
      </div>
    </div>

    <div layout='column' layout-gt-sm='row' layout-padding='1em'>
        <div flex='95' flex-gt-sm='30'>
            <p style='font-size: 1.4em;'><strong><i class="fal fa-book fa-xl"></i> Frequently Asked Questions</strong></p>
            <p>Our user guide has a handy FAQ, use it to check if we already have an answer to your question.</p>
            <div class='support-button' style='font-size: 0.9em'>
                <md-button class='md-raised'>
                    <a ng-href="{{faqSite}}" target="_blank">
                        <span>View the FAQ</span>
                    </a>
                </md-button>
            </div>
        </div>
        <div flex='5'></div>
        <div flex='95' flex-gt-sm='30'>
            <p style='font-size: 1.4em;'><i class="fal fa-lightbulb fa-xl"></i> <strong>Suggest an improvement</strong></p>
            <p>Is there something new you would like us to add?</p>
            <p>The feature you want to see may be on its way already, but we’re always happy to hear about how we can improve.</p>
            <div class='support-button' style='font-size: 0.9em'>
                <md-button class='md-raised'>
                    <a ng-href="{{suggestionSite}}" target="_blank">
                        <span>Send your suggestion</span>
                    </a>
                </md-button>
            </div>
        </div>
        <div flex='5'></div>
        <div flex='95' flex-gt-sm='30'>
            <p style='font-size: 1.4em;'><i class="fal fa-tools fa-xl"></i> <strong>Report a problem</strong></p>
            <p>If you have an operational issue, please contact your administrator.</P>
            <p>Otherwise, if you have encountered a technical fault please save a screenshot if you can and tell us about what happened.</p>
            <div class='support-button' style='font-size: 0.9em'>
                <md-button class='md-raised'>
                    <a ng-href="{{supportSite}}" target="_blank">
                        <span>Report your problem</span>
                    </a>
                </md-button>
            </div>
        </div>
    </div>

    <md-divider></md-divider>

    <div layout='row' style='margin-top:1.5em; margin-bottom:0.4em' layout-padding='1em'>
      <div flex='95' md-colors="{color: 'primary-300'}" style='font-size: 1.5em;'>
        About {{productName}}
      </div>
    </div>

    <div layout='row' layout-padding='1em'>
      <p flex='80' flex-xs='100' class='about-subheading'>
      {{productName}} is an easy to use data capture and processing system that helps to support health and wellbeing.
      </p>
    </div>
    
    <div md-colors="{color: 'primary-300'}" style='margin: 0.75em 0 0.5em 0'>
      System ID (SID)
    </div>


    <div class='version-info' md-colors="{background: 'primary-A100'}" style="margin-bottom: 1em">
        When sharing data using
      <a target="_blank" href="https://downloadmydata.co.uk">downloadmydata.co.uk</a> ensure you give the recipient
       this code: <span style="font-size:1.3em; color:#000">{{systemID}}</span>
    </div>

    <div md-colors="{color: 'primary-300'}" style='margin: 0.75em 0 0.5em 0'>
      Version Info
    </div>

    <div class='version-info' md-colors="{background: 'primary-A100'}">
      <div layout='column' layout-gt-sm='row'>
        <div flex='60'>
          <div md-colors="{color: 'primary-300'}">{{productName}} Version No.</div>
          <div class='version-info__content'> {{ composeVersion }}</div>
        </div>
        <div flex='40'>
          <div md-colors="{color: 'primary-300'}">Vendor</div>
          <div>Science and Engineering Applications Ltd.</div>
        </div>
      </div>


      <div ng-if='composeTags' md-colors="{color: 'primary-300'}">Tags</div>
      <div>
        <md-chips>
          <md-chip class='about__tag' ng-repeat='tag in composeTags'>{{ tag }}</md-chip>
        </md-chips>
      </div>

      <div ng-if='expanded' class='version-info__expanded'>
      <div md-colors="{color: 'primary-300'}">
        Client Version No.
      </div>
      <div class='version-info__content'> {{ chaiVersion }}</div>
      </div>

      <div ng-if='expanded' class='version-info__expanded'>
      <div md-colors="{color: 'primary-300'}">
        Backend Version No.
      </div>
      <div class='version-info__content'> {{ backendVersion }}</div>
      </div>

      <div ng-if='expanded' class='version-info__expanded'>
      <div md-colors="{color: 'primary-300'}">
        Print Version No.
      </div>
      <div class='version-info__content'> {{ printVersion }}</div>
      </div>

    </div>

      <div ng-if='!expanded' class='about__arrow' md-colors="{color: 'primary-300'}">

          <span style='bottom: .25em'
                class="icon1-expand-arrow-down-blue" ng-click='toggle()'>
            <span class="path1"></span><span class="path2"></span>
          </span>
      </div>
      <div ng-if='expanded' class='about__arrow' md-colors="{color: 'primary-300'}">
          <span style='bottom: 1.05em'
                class="icon1-expand-arrow-up-blue" ng-click='toggle()'>
            <span class="path1"></span><span class="path2"></span><span class="path3"></span>
          </span>
      </div>


    <div md-colors="{color: 'primary-300'}" style='margin: 0.75em 0 0.5em 0'>
      Cards Info
    </div>


    <div class='version-info' md-colors="{background: 'primary-A100'}">
      <div layout='column' layout-gt-sm='row'>
        <div flex>
          <div md-colors="{color: 'primary-300'}">Cards Version No.</div>
          <div class='version-info__content'> {{ cardVersion }}</div>
        </div>

      </div>

      <div ng-if='cardTags' md-colors="{color: 'primary-300'}">Tags</div>
      <div>
        <md-chips>
          <md-chip class='about__tag' ng-repeat='tag in cardTags'>{{ tag }}</md-chip>
        </md-chips>
      </div>
    </div>
    
    <div md-colors="{color: 'primary-300'}" style='margin: 0.75em 0 0.5em 0'>
      Debug Info
    </div>


    <div class='version-info' md-colors="{background: 'primary-A100'}">
      <div layout='column' layout-gt-sm='row'>
        <div flex>
          <div md-colors="{color: 'primary-300'}">Client Clock Skew: <span class='version-info__content'> {{ clockSkew }} Seconds</span>  </div>
        </div>

      </div>
    </div>

  </div>
    <div class='support-button'>
      <md-button class='md-raised' md-colors="{color: 'primary-300'}" >
        <i class="far fa-envelope"></i>
           <a href="{{supportSite}}" target="_blank">
                <span>Send these details to support</span>
           </a>
      </md-button>
    </div>

        </md-dialog-content>
        </md-dialog>
      `,
                controller: function DialogController($scope, $mdDialog, $rootScope, aboutStore, Server) {

                    $scope.expanded = false;
                    $scope.toggle = function () {
                        $scope.expanded = !$scope.expanded;
                    };

                    Server.getConfig().then(function (config){
                        //Hard coded support site URL so we can pass params
                        $scope.faqSite = config.faqSite;
                        $scope.suggestionSite = config.suggestionSite;
                        $scope.supportSite = "https://docs.google.com/forms/d/e/1FAIpQLSet3Fril0zOBjbQrk_mfNXYfvUcYBuH0a1pmGtWwXHowOX6hg/viewform?" +
                            "entry.686015725=" + navigator.userAgent + "&" +
                            "entry.418491884=" + config.systemID + "&" +

                            "entry.1111174575=" ;
                        $scope.productName = config.productName;
                        $scope.systemID = config.systemID;
                    });
                    $scope.clockSkew = $rootScope.clockSkew;

                    aboutStore.card().then(info => {
                        if (info.build) {
                            $scope.cardVersion = info.build.git;
                            $scope.supportSite += "cards build: " + info.build.git;

                            $scope.cardTags = info.build.tags;
                            $scope.supportSite += "cards tags: " + info.build.tags;

                        }
                    }).catch(err => {
                        $scope.cardVersion = 'Not Known';
                        $scope.cardTags = [];
                    });


                    aboutStore.backend().then(info => {
                        $scope.backendVersion = info.build.Build;
                        $scope.supportSite += "backend build: " + info.build.Build;

                    }).catch(err => {
                        $scope.backendVersion = 'Not Known'
                    });

                    aboutStore.print().then(info => {
                        $scope.printVersion = info.build.git;
                    }).catch(err => {
                        $scope.printVersion = 'Not Known'
                    });


                    aboutStore.compose().then(info => {
                        if (info.build) {
                            $scope.composeVersion = info.build.git;
                            $scope.composeTags = info.build.tags;
                        }
                    }).catch(err => {
                        $scope.composeVersion = 'Not Known';
                        $scope.composeTags = [];
                    });

                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }
            });
        }
    })

    .directive('about', function () {
        return {
            restrict: 'E',
            scope: {},
            controller: 'AboutController',
            template: `
      <div class='footer'>
        <div  ui-sref="home" class='footer__logo'>
        <img width="50px" src="assets/images/CAR-logo-square.png" alt="Care and respond logo" />
        </div>

        <div layout='column' class='footer__links'>
           <div layout='row' layout-align='space-between'>
              <div ng-click='showAboutCHAIDialog()' class='footer__link'>About {{productName}} | Help</div>
              <div target="_blank" class='footer__link'>
                  <a translate href="{{faqSite}}" target="_blank">footer_2</a>              </div>
            </div>
            <hr class='footer__separator'>
            <div layout='row' layout-align='space-between' class='footer__subinfo'>
              <div translate style='color: #666'>footer_4</div>
              <div translate ng-click='showTandCDialog()' class='footer__link'>footer_3</div>
            </div>
      </div>
    </div>
    `
        };
    })
