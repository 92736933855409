/*
 * @name video
 * @ngdoc module
 * @description
 * Search and List Videos
 */

angular
  .module('videos', ['ngMaterial', 'materialComponentsTable'])

  .service('VideoServiceStore', function ($http, $q, Server) {
    this.load = function () {
      return Server.createResource('/films', {
        topics: {
          method: 'GET',
          url: '/films/topics',
          isArray: true
        },
        all: {
          method: 'GET',
          url: '/films/all',
          isArray: true
        },
        search: {
          method: 'GET',
          url: '/films/search',
          isArray: true
        }
      });
    };
    this.search = function (searchParam, scope) {
      let params = {};
      params.query = searchParam;
      this.load().then((VideoServiceStore) => {
        let obj = VideoServiceStore.search(params);
        obj.$promise.then((result) => {
          scope.videoSearch = result;
          scope.updateFilter();
        });
      });
    };
  })
  .controller(
    'videosController',
    function ($scope, $rootScope, VideoServiceStore, $mdDialog, $window) {
      $scope.message = [
        'This is where your results will appear,',
        'there are no results to show right now'
      ].join(' ');
      $scope.advice = '';
      $scope.vidState = true;

      // Topic filter
      $scope.toggleVideoTopic = function (topic) {
        const lowercaseTopic = topic.toLowerCase();
        if (!$scope.selectedTopics.has(lowercaseTopic)) {
          $scope.selectedTopics.add(lowercaseTopic);
        } else {
          $scope.selectedTopics.delete(lowercaseTopic);
        }
        $scope.updateFilter();
      };
      $scope.toggleVideoTag = function (tag) {
        if (!$scope.selectedTags.has(tag)) {
          $scope.selectedTags.add(tag);
        } else {
          $scope.selectedTags.delete(tag);
        }
        $scope.updateFilter();
      };
      $scope.search = function () {
        $scope.selectedTags = new Set();
        $scope.selectedTopics = new Set();
        //reset seelction filters
        VideoServiceStore.search($scope.searchText, $scope);
      };

      $scope.updateFilter = function () {
        var newVidList = new Set();
        const useSearch =
          $scope.videoSearch != undefined &&
          $scope.videoSearch.length > 0 &&
          $scope.searchText.length > 0;
        if (useSearch) {
          $scope.videoSearch.forEach(newVidList.add, newVidList);
        }
        else if ($scope.selectedTags.size == 0 && $scope.selectedTopics.size == 0) {
          //Reset everything
          $scope.allVideoList.forEach(newVidList.add, newVidList);
          $scope.videoSearch = [];
          $scope.tags = [];
          newVidList = [];
        }
        else {
          $scope.allVideoList.forEach(newVidList.add, newVidList);
          $scope.videoSearch = [];
        }
        if ($scope.selectedTopics.size > 0) {
          var currentSelectedTopics = $scope.selectedTopics;
          var topicFilms = new Set();
          Array.from(newVidList)
            .filter((video) =>
              currentSelectedTopics.has(video.topic.toLowerCase())
            )
            .forEach(topicFilms.add, topicFilms);
          newVidList = topicFilms;
        }

        if ($scope.selectedTags.size > 0) {
          var tagFilms = new Set();
          for (const tag of $scope.selectedTags) {
            Array.from(newVidList)
              .filter((video) => video.tags.indexOf(tag) > -1)
              .forEach(tagFilms.add, tagFilms);
          newVidList = tagFilms;
        }
        }

        $scope.videoList = Array.from(newVidList);
        allTags = [];
        if ($scope.selectedTags !== undefined) {
          $scope.selectedTags.forEach((tag) => {
          tag.trim();
          allTags.push({ tag: tag, count: 0 });
        });
       }
        allTopics = [];
        for (const video of newVidList) {
          incrementTags(allTags, video.tags);
          incrementTopics(allTopics, video.topic);
        }

        function incrementTags(allTags, tags) {
          function findTagIndex(tag, allTags) {
            for (var index in allTags) {
              var obj = allTags[index];
              if (allTags[index].tag === tag) {
                return index;
              }
            }
            return -1;
          }
          for (var indx in tags) {
            var tag = tags[indx].trim();
            currentCount = 1;
            oldCount = findTagIndex(tag, allTags);
            if (oldCount === -1) {
              allTags.push({ tag: tag, count: 1 });
            } else {
              var c = allTags[oldCount].count + 1;
              var newC = { tag: tag, count: c };
              allTags[oldCount] = newC;
            }
          }
        }
        function incrementTopics(allTopics, topic) {
          function findTopicIndex(topic, allTopics) {
            var topic = topic.trim();
            for (var index in allTopics) {
              var obj = allTopics[index];
              if (allTopics[index].topic === topic) {
                return index;
              }
            }
            return -1;
          }

          currentCount = 1;
          oldCount = findTopicIndex(topic, allTopics);
          if (oldCount === -1) {
            allTopics.push({ topic: topic, count: 1 });
          } else {
            var c = allTopics[oldCount].count + 1;
            var newC = { topic: topic, count: c };
            allTopics[oldCount] = newC;
          }
        }
        if (useSearch) {
          $scope.topics = allTopics
            .filter((topic) => topic.count > 0)
            .sort(function (a, b) {
              return b.count - a.count;
            });
        }
        else
        {
          $scope.topics = $scope.allTopics;
        }
        $scope.tags = allTags
          .filter((tag) => tag.count > 1 || $scope.selectedTags.has(tag.tag))
          .sort(function (a, b) {
            return b.count - a.count;
          });
      };

      $scope.closeandToggle = function (videoTagIn) {
        alert('Start');
        $scope.toggleVideo('');
        alert('Close');
        $scope.closeDialog();
        alert('and Toggle');
      };
      VideoServiceStore.load().then((videoResource) => {
        $scope.allTopics = videoResource.topics();
        $scope.topics = $scope.allTopics ;
      });
      VideoServiceStore.load().then((videoResource) => {
        $scope.allVideoList = videoResource.all();
      });
      // END Temporary Video Toggle
      $scope.tags = [];
      $scope.topics = [];
      $scope.allTopics = [];
      $scope.videoList = [];
      $scope.videoSearch = [];

      $scope.selectedTopics = new Set();
      $scope.selectedTags = new Set();

      // Open a panel with single video
      $scope.showVideoPanel = function (video) {
        $scope.video = video;
        selectedTags = $scope.selectedTags;
        updateFilter = $scope.updateFilter;
        $mdDialog.show({
          parent: angular.element(document.body),
          clickOutsideToClose: true,
          isolateScope: false,

          template: `
                    <md-dialog aria-label={{video.title}}>
                    <md-dialog-content class="filmpanel">
                    <div layout='row'>
                        <div flex='90' >  
                          <h2 class="filmtitle">{{video.title}}</h2>  
                        </div>
                        <div flex='10' style='text-align: end'>
                        <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i> 
                        </div>
                    </div>
                    
                    <iframe src="{{video.url | trustUrl}}"frameborder="0"allowfullscreen ></iframe> 
                                      
                    <div class="infopanel">                      
                        <div>                      
                          <p>{{video.description}}</p>
                        </div>
                          <h3>Video Tags <span class="taginfo">Click a tag to view all related films.</span></h3>
                          
                          <div class="taglist">
                            <fieldset class="chips">
                                <button ng-repeat='tag in video.tags'  class="chip" ng-click='closeDialog(tag)'>
                                <span>
                                    {{tag}}
                                </span>
                                </button>            
                            </fieldset>
                          </div>
                    </div>
                                
                    </md-dialog-content>
                    </md-dialog>
                `,
          controller: function DialogController($scope, $mdDialog, $window) {
            $scope.video = video;
            $scope.selectedTags = selectedTags;
            $scope.updateFilter = updateFilter;

            $scope.closeDialog = function (tag) {
              if (tag !== undefined) {
                $scope.selectedTags.add(tag);
                $scope.updateFilter();
              }
              $mdDialog.hide();
            };
          }
        });
      };
      // END showVideoPanel
    }
  )

  // Display Search, tag chips and Video List
  .directive('videos', function () {
    return {
      restrict: 'E',
      scope: {},
      controller: 'videosController',
      template: `    
            <md-content class="md-padding">
            <form ng-submit='search()' class="video-search">


              <!-- BEGIN Searchbar Display -->

              <fieldset class="searchbar">
                <label id="videoSearchLabel">Search Films</label>
                <div class="searchinput">
                  <input type="text" ng-model='searchText'
                      placeholder="Begin searching"
                      id="videoSearchLabel"
                      flex=""/>
                  <button class="capitalize searchbar"><i class="fa-solid fa-magnifying-glass"></i> Search</button>
                </div>
              </fieldset>
            </form>
              <!-- END Searchbar Display -->
              
              <div style="padding:0.8em 0.5em">
                <p>We are building a library of high quality short films, generally made in collaboration with the NHS.</p>             
                <p>This service is in BETA and so please help improve it by reporting any errors to us using our <a href="https://www.careandrespond.com/contact/technical-support/" title="Technical support form">contact form</a>.  Please also suggest any topics that would would like us to include <a href="https://www.careandrespond.com/contact/improvement/" title="Suggest an improvement">here.</a></p>
              </div>
            
              <!-- BEGIN Topic Display -->
              <md-card>
              <fieldset class="chips topics">
                <legend>Film Topics 
                  <span class="taginfo">Choose one or more topics to see the film options and their search tags.</span>
                </legend>
                <tag-field ng-repeat='topic in topics'>
                <input type="checkbox" class="chip" id={{topic.topic}} name="topic_tag" value={{topic.topic}}> 
                  <label for={{topic.topic}} class="chip topic" ng-click="toggleVideoTopic(topic.topic)">
                    <span>           
                      <strong>{{topic.topic}}</strong>
                      <em>({{topic.count}})</em>
                    </span>
                  </label>
                </tag-field>                
              </fieldset>
              </md-card>
                              
              <!-- END Tag Display -->

              <!-- BEGIN Tag Display -->
              <div ng-if="tags.length>0">                    

              <fieldset class="chips">
                <legend>Film Tags 
                  <span class="taginfo">Choose one of more tags to narrow down your search of the films below.</span>
                </legend>
                <tag-field ng-repeat='tag in tags'>
                <input type="checkbox"class="chip"id={{tag.tag}} ng-checked="selectedTags.has(tag.tag)" name="video_tag" value={{tag.tag}}> 
                  <label for={{tag.tag}} class="chip tag"ng-click="toggleVideoTag(tag.tag)">
                    <span>                    
                      <strong>{{tag.tag}}</strong>

                    </span>
                  </label>
                </tag-field>
                
              </fieldset>
                              
              </div>
              <!-- END Tag Display -->

          </md-content>
          <div ng-if="videoList.length>0" > 
          <md-content class="md-padding videolist"layout="row"layout-wrap="">   
            <h3 flex="100">Film List</h3>
            <video-field ng-repeat='video in videoList'>
            <md-card ng-click="showVideoPanel(video)"ng-if="vidState"class="caramelvid"> 
              <div class="overlay"></div>
              <div class="embed">
                <iframe  ng-click="showVideoPanel(video)"ng-if="vidState"src="{{video.thumbnail | trustUrl}}"frameborder="0"></iframe>
              </div>                           
                <md-card-title>
                  <md-card-title-text>
                    <span class="md-headline">{{video.title}}</span>
                  </md-card-title-text>
                </md-card-title>
            </md-card>  
            </video-field>
            
          </md-content>
        </div>
          <md-content>
                  {{ loadVids(tag_breathless)}}            
          </md-content>                  
    `
    };
  });
    // END main videos directive
