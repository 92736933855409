/**
 * @name passports
 * @ngdoc module
 * @description
 * Passport specific services and functionality
 */

angular.module('passports', [])

    .service('PassportService', function () {

        const service = this;

        const _passportForms = ['full_passport', 'emergency_passport'];

        service.passportForms= function (){
            return _passportForms;
        };


        service.isPassportForm = function (formName){
            const matched = _passportForms.find(passportName => formName === passportName);
            return matched && matched.length > 0;
        };



    }).service('PassportShareStore', function ($http, $q, Server) {

        this.loadStore = function () {
            return Server.createResource(`/patient/:patientId/supporters`, {
                currentShares: {
                    method: 'GET',
                    url: `/patient/:patientId/supporters`,
                    isArray: true
                },
                supporting: {
                    method: 'GET',
                    url: '/users/:username/supportgroups',
                    isArray:true
                },
                newShare: {
                    method: 'POST',
                    url: `/patient/:patientId/supportgroups/passport/members`,
                    transformResponse: function(data, headers,statusCode) {
                        return statusCode;
                    }
                },
                removeShare: {
                    method: 'DELETE',
                    url: '/patient/:patientId/supportgroups/passport/members/:member',
                    transformResponse: function(data, headers,statusCode) {
                        return statusCode;
                    }
                }
            });
        };

       this.viewPassportShares = function (patientId) {
           return this.loadStore().then(function (Store){
               return Store.currentShares({
                   patientId: patientId
               }) .$promise.then(shares => {
                   return shares.filter(share => share.passport === "accepted" || share.passport === "pending");
               });
            });
       };

    this.passportsSharedWithUser = function (username) {
        return this.loadStore().then(function (Store){
            return Store.supporting({
                username: username
            }) .$promise.then(shares => {
                return shares.filter(share => share.passport === "accepted");
            });
        });
    };

       this.newPassportShare = function (patientId, newShare) {
           return this.loadStore().then(function (Store){
             return Store.newShare({ patientId: patientId}, newShare).$promise.catch(function (err) {
                 return err.status;
             });
           });
       };

       this.removePassportShare = function (patientId, username) {
           return this.loadStore().then(function (Store){
               return Store.removeShare({ patientId: patientId, member: username}).$promise;
           });
       };

    });
