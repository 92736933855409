angular.module('nav', [])
    .directive('clickOutside', function ($document, $window) {

        return {
            restrict: 'A',
            scope: {
                clickOutside: '&'
            },
            link: function (scope, el, attr) {

                var clickHandler = function (e) {
                    if (el !== e.target && !el[0].contains(e.target)) {
                        setTimeout(function () {
                            scope.$apply(function () {
                                scope.clickOutside();
                            });
                        });
                    }
                };

                scope.$watch(function () {
                    var elementStyle = $window.getComputedStyle(el[0], null);
                    return elementStyle.display !== 'none';
                }, function (isVisible, oldVal) {
                    setTimeout(function () {
                        if (oldVal !== isVisible) {
                            if (isVisible) {
                                $document.on('click', clickHandler);
                            } else {
                                $document.off('click', clickHandler);
                            }
                        }
                    }, 300);
                });

            }
        };

    })
    .directive('sideNavigationMenu', function () {
        return {
            controller: function ($scope, $mdSidenav, $rootScope, RepeatingFormStore, $mdDialog, $state, Printer, $mdToast, LoggedInUser) {

                var sections = [{
                    name: 'Home',
                    url: 'home',
                    icon: 'far fa-house',
                    type: 'link'
                }];

                sections.push({
                    name: 'My Details',
                    type: 'heading',
                    children: [
                        {
                            name: 'My Profile',
                            type: 'link',
                            icon: 'fa fa-clipboard-list',
                            url: 'patient.group',
                            urlArgs: function () {
                                return {id: LoggedInUser.getPatientId(), group: 0, form: "admissions", card: ""};
                            }
                        },
                        {
                            name: 'My Health',
                            type: 'link',
                            icon: 'fa fa-notes-medical',
                            url: 'patient.group',
                            urlArgs: function () {
                                return {id: LoggedInUser.getPatientId(), group: 1, form: "admissions", card: ""};
                            }
                        },
                        {
                            name: 'Connected Services',
                            type: 'link',
                            url: 'patient.group',
                            icon: "fa-duotone fa-link",
                            urlArgs: function () {
                                return {
                                    id: LoggedInUser.getPatientId(),
                                    group: 0,
                                    form: "connected_services",
                                    card: "data_sharing_card"
                                };
                            }
                        }
                    ]
                });


                /**
                 * creates a click handler for view passport links
                 * @param form
                 * @returns {(function(): void)|*}
                 */
                function printPassportFn(form) {
                    return function () {
                        function showPlaceHolder(form) {
                            $mdDialog.show({
                                parent: angular.element(document.body),
                                clickOutsideToClose: true,
                                template: `
                                <md-dialog aria-label='Creating a Passport'>
                                <md-dialog-content style='padding: 1em; color: #666'>
                        
                                  <div layout='row'>
                                    <div flex='95' md-colors="{color: 'primary-300'}" style='font-size: 1.25em'>
                                      <p>Before you can view/download your {{passportName}} you must:
                                      <ol>
                                      <li>Fill out your Health Details</li>
                                      <li>Validate the contents of your {{passportName}}</li>
                                    </ol></p>
                                    </div>
                                    <div flex='5' style='text-align: end'>
                                  
                                      <i style='cursor:pointer' class='fas fa-times' ng-click='closeDialog()'></i>
                                    </div>
                                  </div>
                       
                                </md-dialog-content>
                                </md-dialog>
                              `,
                                controller: function DialogController($scope, $mdDialog) {
                                    $scope.passportName = form === 'full_passport' ? 'Full Passport' : 'Emergency Passport';
                                    $scope.closeDialog = function () {
                                        $mdDialog.hide();
                                    };
                                }
                            });
                        }

                        function print() {
                            var info = {
                                form: form,
                                id: LoggedInUser.getPatientId(),
                                type: 'custom',
                                ewstype: $rootScope.ewsType,
                                username: LoggedInUser.getPatientId()
                            };

                            Printer.requestURL(info)
                                .then(function (data) {
                                    if (data.response.success) {
                                        const url = `${data.httpConfig.url}/${data.response.url}`;
                                        window.open(url);
                                    } else {
                                        $mdToast.show({
                                            template: '<md-toast>' + data.response.url + '</md-toast>',
                                            hideDelay: 2000,
                                            position: 'bottom center'
                                        });
                                    }
                                })
                                .catch(function (err) {
                                    console.log(err);
                                });
                        }

                        RepeatingFormStore.load().then(DataStore => {
                            return DataStore.valuesCount({
                                id: LoggedInUser.getPatientId(),
                                card: form,
                                count: 1,
                                offset: 0
                            }).$promise.then(function (data) {
                                if (data && data.length > 0) {
                                    print();
                                } else {
                                    showPlaceHolder(form);
                                }

                            }, err => {
                                showPlaceHolder(form);
                            });
                        });
                    };
                }

                sections.push({
                    name: 'Support Network',
                    type: 'heading',
                    children: [
                        {
                            name: 'My Supporters',
                            type: 'link',
                            external: true,
                            icon: 'fa-duotone fa-user',
                            url: '/support/en/supporters'
                        },
                        {
                            external: true,
                            icon: 'far fa-hand-holding-heart',
                            name: 'People I Support',
                            type: 'link',
                            url: '/support/en/supporting'
                        },
                        {
                            icon: 'far fa-share-from-square',
                            name: 'Passport Sharing',
                            type: 'link',
                            url: "patient.sharepassport",
                            urlArgs: function () {
                                return {id: LoggedInUser.getPatientId()};
                            },
                        }
                    ]
                });

                sections.push({
                    name: 'Passports',
                    type: 'heading',
                    children: [
                        {
                            name: 'Full Passport',
                            type: 'toggle',
                            pages: [
                                {
                                    name: 'Create/Validate Passport',
                                    type: 'link',
                                    icon: 'far fa-list-check',
                                    url: 'patient.passport',
                                    urlArgs: function () {
                                        return {id: LoggedInUser.getPatientId(), type: 'full'};
                                    }
                                },
                                {
                                    name: 'View Passport',
                                    type: 'link',
                                    icon: 'fas fa-book-medical',
                                    onClickFn: printPassportFn('full_passport')
                                }
                            ]
                        },
                        {
                            name: 'Emergency Passport',
                            type: 'toggle',
                            pages: [
                                {
                                    name: 'Create/Validate Passport',
                                    type: 'link',
                                    icon: 'far fa-list-check',
                                    url: 'patient.passport',
                                    urlArgs: function () {
                                        return {id: LoggedInUser.getPatientId(), type: 'emergency'};
                                    }
                                },
                                {
                                    name: 'View Passport',
                                    icon: 'far fa-file-exclamation',
                                    type: 'link',
                                    onClickFn: printPassportFn('emergency_passport')
                                }
                            ]
                        },
                        {
                            name: 'Shared With Me',
                            type: 'link',
                            icon: 'fa-thin fa-rectangle-history-circle-user',
                            url: 'patient.sharedwithme',
                            urlArgs: function () {
                                return {id: 'me'};
                            }
                        },
                    ]
                });

                $mdSidenav("left", true).then(function(instance) {
                    instance.onClose(function() {
                        const bodyElement = angular.element( document.querySelector( '#app-body' ) );
                        bodyElement[0].style.overflow='auto';
                    });
                });

                var openedSection = null;
                $scope.isOpen = function (section) {
                    return section === openedSection;
                };

                $scope.toggleOpen = function (section) {
                    if (section === openedSection) {
                        openedSection = null;
                    } else {
                        openedSection = section;
                    }
                };

                $scope.sections = sections;

                $scope.walkthrough = {
                    name: 'Walkthrough',
                    type: 'link',
                    icon: 'fa-light fa-shoe-prints',
                    onClickFn : function (){
                        LoggedInUser.setWalkThroughPrefs({});
                        $state.go('home', {},{ reload: 'home'});
                    }
                };

                $scope.logoutLink = {
                    url: 'logout',
                    icon: 'fas fa-sign-out-alt',
                    name: 'Logout',
                    type: 'link'
                };


                $scope.accountSettingsLink =
                    {
                        url: '/keycloak/auth/realms/chai/account/',
                        icon: 'fas fa-lock',
                        name: 'Account Settings',
                        type: 'link',
                        external: true
                    };

                $scope.videoLink =
                    {
                        url: 'video',
                        icon: 'fas fa-film',
                        name: 'Short Films about Health',
                        type: 'link',
                        external: false
                    };

                function buildToggler(componentId) {
                    return function () {
                        $mdSidenav(componentId).toggle();
                    };
                }

                $scope.toggleLeft = buildToggler('left');
            },
            template: `
               <md-sidenav layout="column" class="md-sidenav-left" md-disable-scroll-target="#app-body" md-component-id="left" md-whiteframe="4">

        <div layout="row">
        
        <div flex></div>
        <md-button class="md-icon-button md-accent right" ng-click="toggleLeft()"><i class="far fa-xmark fa-2x"></i></md-button>
        </div>

      <md-content flex layout="column" role="navigation">          
        <ul class="side-menu">
        <li ng-repeat="section in sections" class="parent-list-item {{section.className || ''}}">
          <h2 style="padding: 1em" class="menu-heading md-subheader" ng-if="section.type === 'heading'" id="heading_{{ section.name | nospace }}">
            {{section.name}}
          </h2>
          <menu-link section="section" ng-if="section.type === 'link' && !section.hidden"></menu-link>

          <menu-toggle section="section" ng-if="section.type === 'toggle' && !section.hidden"></menu-toggle>

          <ul ng-if="section.children" class="menu-nested-list">
            <li ng-repeat="child in section.children">
              <menu-link section="child" ng-if="child.type === 'link'"></menu-link>

              <menu-toggle section="child" ng-if="child.type === 'toggle'"></menu-toggle>
            </li>
          </ul>
        </li>
        <li class="parent-list-item" >
            <ul class="menu-nested-list">
                <li>
                    <menu-link section="videoLink"></menu-link>                    
                </li>
            </ul>
        </li>
      </ul>
      
      <md-divider flex></md-divider>
      <menu-link section="walkthrough"></menu-link>
      <menu-link section="accountSettingsLink"></menu-link>
      <menu-link section="logoutLink"></menu-link>
      

      </md-content>

    </md-sidenav>`
        };
    })
    .filter('nospace', function () {
        return function (value) {
            return (!value) ? '' : value.replace(/ /g, '');
        };
    })
    .directive('menuToggle', ['$mdUtil', '$animateCss', '$$rAF', function ($mdUtil, $animateCss, $$rAF) {
        return {
            scope: {
                section: '='
            },
            template: `
            
            <md-list-item  class="md-button-toggle"
              ng-class="section.icon"
              aria-controls="side-menu-{{section.name | nospace}}"
              aria-expanded="{{isOpen()}}">
              <div ng-click="toggle()" flex layout="row" style="color: #0D3349!important;">
                {{section.name}}
                <span flex></span>
                <span aria-hidden="true" class="md-toggle-icon"
                ng-class="{'toggled' : isOpen()}">
                  <i class="far fa-chevron-right"></i>
                </span>
              </div>
             
            </md-list-item>
            
            
            <ul id="side-menu-{{section.name | nospace}}"
              class="menu-toggle-list"
              ng-style="{ visibility: renderContent ? 'visible' : 'hidden' }">
            
              <li ng-repeat="page in section.pages">
                <menu-link section="page"></menu-link>
              </li>
            </ul>
`,
            link: function ($scope, $element) {
                var controller = $scope.$parent;

                $scope.renderContent = false;

                $scope.isOpen = function () {
                    return controller.isOpen($scope.section);
                };

                $scope.toggle = function () {
                    controller.toggleOpen($scope.section);
                };

                $mdUtil.nextTick(function () {
                    $scope.$watch(function () {
                        return controller.isOpen($scope.section);
                    }, function (open) {
                        var $ul = $element.find('ul');
                        var $li = $ul[0].querySelector('a.active');

                        if (open) {
                            $scope.renderContent = true;
                        }

                        $$rAF(function () {
                            var targetHeight = open ? $ul[0].scrollHeight : 0;

                            $animateCss($ul, {
                                easing: 'cubic-bezier(0.35, 0, 0.25, 1)',
                                to: {height: targetHeight + 'px'},
                                duration: 0.4 // seconds
                            }).start().then(function () {
                                var $li = $ul[0].querySelector('a.active');

                                $scope.renderContent = open;

                                if (open && $li && $ul[0].scrollTop === 0) {
                                    var activeHeight = $li.scrollHeight;
                                    var activeOffset = $li.offsetTop;
                                    var offsetParent = $li.offsetParent;
                                    var parentScrollPosition = offsetParent ? offsetParent.offsetTop : 0;

                                    // Reduce it a bit (2 list items' height worth) so it doesn't touch the nav
                                    var negativeOffset = activeHeight * 2;
                                    var newScrollTop = activeOffset + parentScrollPosition - negativeOffset;

                                    $mdUtil.animateScrollTo(document.querySelector('.side-menu').parentNode, newScrollTop);
                                }
                            });
                        });
                    });
                });

            }
        };
    }])

    .directive('menuLink', function ($mdSidenav) {
        return {
            scope: {
                section: '='
            },
            template: `
            <md-list-item ng-click="onClick()" ng-if="!section.external">
                <i ng-class="[section.icon]" class="menu-icon"></i>
                <span>{{ section.name }} </span>
                <md-divider></md-divider>
            </md-list-item>
            <md-list-item ng-href="{{section.url}}" ng-if="section.external">
                 <i ng-class="[section.icon]" class="menu-icon"></i>
                <span>{{ section.name }} </span>
                 <md-divider></md-divider>
            </md-list-item>
`,
            controller: function ($scope, $state) {

                $scope.onClick = function () {
                    if ($scope.section.onClickFn) {
                        $scope.section.onClickFn();
                    } else {
                        $state.go($scope.section.url, ($scope.section.urlArgs && $scope.section.urlArgs()) || {});
                    }
                    $mdSidenav('left').toggle();
                };
            }
        };
    })
    /**
     * @name navigationBar
     * @ngdoc directive
     * @restrict E
     * @description
     * The navigation bar at the top of the screen.
     *
     * If $rootScope.signoff is defined, it will not show any navigation options
     */
    .directive('navigationBar', function () {
        return {
            restrict: 'E',

            controller: function ($scope, $rootScope, $state, $document, $timeout, $window, $mdSidenav, PatientStore) {


                $scope.trainee = $rootScope.trainee;
                $scope.isAManager = $rootScope.isAManager;
                $scope.canSubmitForms = $rootScope.canSubmitForms;
                $scope.fullName = $rootScope.fullName;
                $scope.canSearchPatients = $rootScope.canSearchPatients;
                $scope.canAddPatients = $rootScope.canAddPatients;
                $scope.locale = $rootScope.locale;
                $scope.patientId = $rootScope.patientId;
                $scope.plans = $rootScope.carePlans;

                $scope.passport = true;

                $scope.signoff = $rootScope.signoff;

                $scope.currentState = $state.current.name;

                $scope.createPatient = function () {
                    PatientStore.load().then(Patient => {
                        Patient.save({}, function (patient) {
                            $state.go('home');
                        }, err => {
                        });
                    });
                };

                $scope.menuOpen = false;

                $scope.toggleMenuOpen = function () {
                    $timeout(function () {
                        $mdSidenav('left').toggle();
                    });
                };

                $scope.onMenuItemClick = function (link, state) {
                    $scope.hideMenu();
                    $state.go(link, state);
                };

                $scope.hideMenu = function () {
                    $scope.menuOpen = false;
                };


                // $scope.refresh = function () {
                //   $state.go('patient.group', {
                //    id: $rootScope.patientId,
                //    form: 'admissions',
                //    group: '0',
                //    card: 'patient_details'
                //    });
                // };


            },
            template: `
      <nav ng-if="!signoff" class='global main-nav' md-colors="{color: 'primary-600'}" layout="column">
        <div class='left'>
            <a ui-sref='home' title="Care and Respond Homepage" tabindex="1" class="logo"><img src="assets/images/CAR-Logo-Horizontal.png" alt="Care and respond logo" /></a>
            
            <ul class="main-menu" >
            <li tabindex="2">
            <a ng-click="toggleMenuOpen()"><span class="toplevel">
                <i class="fa-solid fa-bars"></i> Menu</span></a>
              </li>
            </ul>
       

       

      </div>
      <div class='right'>
      <!-- <div class='mini-user'><span translate>header_1</span><br/>{{fullName}}</div> -->
        <i ng-if='trainee' class='icon2-countersign'></i>
        <a hide-xs href='/keycloak/auth/realms/chai/account/' title="Change Password">
          <i class='fas fa-lock'></i>
        </a>
  
        <a ui-sref='logout'>
          <i class='fas fa-sign-out-alt'></i>
        </a>
      </div>
      </nav>

      <nav ng-if="signoff" class='global signoff-nav'>
         <center>
        {{ fullName }} <i class='icon2-countersign'></i> Countersigning Mode
        </center>
      </nav>

    `
        };
    });
